import React from "react";
import { Layout } from "antd";
import Header from "@app/components/Header";

const CommonLayout = (props: any) => {
  return (
    <Layout className="YJ_COMMON_LAYOUT">
      <Header {...props} hideLogout={true} />
      <Layout>
        <Layout className="yj_cp_masterlayout_wrapper">{props.children}</Layout>
      </Layout>
    </Layout>
  );
};
export default CommonLayout;
