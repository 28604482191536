import {
  DATA_LOAD_SUCCESS,
  DATA_LOAD_ERROR,
  DATA_LOADING,
  EDIT_FUNCTIONAL_FLOW,
  SAVE_FUNCTIONAL_FLOW,
  SAVE_SUCCESS_FUNCTIONAL_FLOW,
  SAVE_FAIL_FUNCTIONAL_FLOW,
  REDIRECT_FUNCTIONAL_FLOW,
  VERICAL_LOAD_SUCCESS,
} from "../actionTypes/functionalFlowActionTypes";
import { IFunctionalFlowState } from "../types/functionalFlow/functionalFlow";

export const initialState: IFunctionalFlowState = {
  modules: null,
  functionModules: null,
  errors: [],
  isLoading: true,
  hasErrors: false,
  savedSuccessfully: false,
  isEdited: false,
  editModule: [],
};

export const functionalFlowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        errors: [],
        savedSuccessfully: false,
        isEdited: false,
      };

    case DATA_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        errors: [],
        savedSuccessfully: false,
        isEdited: false,
      };

    case VERICAL_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        errors: [],
        isLoading: false,
        savedSuccessfully: false,
        isEdited: false,
      };

    case EDIT_FUNCTIONAL_FLOW:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        savedSuccessfully: false,
        isEdited: true,
      };

    case DATA_LOAD_ERROR:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
        hasErrors: true,
        savedSuccessfully: false,
      };

    case SAVE_FUNCTIONAL_FLOW:
      return {
        ...state,
        errors: [],
        isLoading: false,
        hasErrors: false,
        savedSuccessfully: false,
      };

    case SAVE_SUCCESS_FUNCTIONAL_FLOW:
      return {
        ...state,
        errors: [],
        isLoading: false,
        hasErrors: false,
        savedSuccessfully: true,
      };

    case SAVE_FAIL_FUNCTIONAL_FLOW:
      return {
        ...state,
        errors: action.payload,
        hasErrors: true,
        isLoading: false,
        savedSuccessfully: false,
      };

    case REDIRECT_FUNCTIONAL_FLOW:
      return {
        ...state,
        errors: [],
        isLoading: false,
        savedSuccessfully: false,
        hasErrors: false,
      };

    default:
      return state;
  }
};
