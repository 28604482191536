import { DataNode } from "rc-tree/lib/interface";
import { FormInstance, Skeleton } from "antd";
import React, { useEffect, useState } from "react";

import { getChannelStatuses } from "@app/api/channelService";
import { formActionType } from "@app/utils";
import Channel from "./index";
export interface ChannelContainer {
  action: formActionType;
  formRef?: FormInstance;
  channelDetailWithFolderStructure: any | undefined;
  onFinish?: (values: any) => void;
  onChange?: () => void;
  onFolderTreeUnmount?: (treeData: DataNode[]) => void;
  onDupicateFolderAdded?: (duplicateName: boolean) => void;
  onEmptyFolderAdded?: (duplicateFolder: boolean) => void;
}

export const mapChannelData = (data: any) => {
  return {
    ...data,
    status: data.status?.value,
  };
};

export const maptoShowChannelData = (data: any) => {
  return {
    ...data,
    role: data.role?.value,
    status: data.status?.value,
    createdBy: data.createdBy ? data.createdBy.name : null,
    created: data.created ? data.created.split("T")[0] : null,
    modified: data.modified ? data.modified.split("T")[0] : null,
  };
};

export default (props: ChannelContainer) => {
  const [channelDetails, setChannelDetials] = useState<{} | undefined>();
  const [formLoaded, setFormLoaded] = useState(false);

  const populateUserDropdownData = () => {
    getChannelStatuses().then((values) => {
      setChannelDetials({
        status: values.data,
      });
    });
  };

  useEffect(() => {
    if (props.channelDetailWithFolderStructure && props.action === "edit") {
      populateUserDropdownData();
      props.formRef?.setFieldsValue(
        maptoShowChannelData(props.channelDetailWithFolderStructure)
      );
      setFormLoaded(true);
    } else {
      if (props.action === "save") {
        populateUserDropdownData();
        props.formRef?.setFieldsValue(mapChannelData({ status: 1 }));
        setFormLoaded(true);
      }
    }
  }, [props.formRef, props.channelDetailWithFolderStructure, props.action]);

  return formLoaded ? (
    <Channel
      onFolderTreeUnmount={props.onFolderTreeUnmount}
      action={props.action}
      onChange={props.onChange}
      onFinish={props.onFinish}
      formRef={props.formRef}
      channelDetails={channelDetails}
      channelDetailWithFolderStructure={props.channelDetailWithFolderStructure}
      onDupicateFolderAdded={props.onDupicateFolderAdded}
      onEmptyFolderAdded={props.onEmptyFolderAdded}
    />
  ) : (
    <Skeleton active />
  );
};
