import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import RouteWithSubRoutes from "./RoutesWithSubRoutes";
import LogoutPage from "@pages/Logout";
import PageNotFound from "@pages/PageNotFound";
import RouteConfigType from "@app/types/RouteConfigType";
import CustomCallback from "@app/components/Login/CustomCallback";

const RouteSwitch = (props: any) => {
  if (!props.routes) {
    return null;
  }
  return (
    <Switch>
      {props.routes.map((route: RouteConfigType) => (
        <RouteWithSubRoutes key={route.path} {...route} />
      ))}
      <Route path="/login/callback" component={CustomCallback} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/page-not-found" component={PageNotFound} />
      <Route path="*">
        <Redirect to="/page-not-found" />
      </Route>
    </Switch>
  );
};

export default RouteSwitch;
