import { AvoidWhitespace } from "@app/utils/regex";
import { Rule } from "antd/lib/form";

const required: Rule = {
  required: true,
  message: "Required Field",
};

const notRequired: Rule = {
  required: false,
};

const max = (length: number): Rule => ({
  max: length,
  message: "You have reached your maximum limit of characters",
});

const min = (length: number, message?: any): Rule => ({
  min: length,
  message: message ? message : "Minimum of 3 characters required",
});

const type = (validatorType: any, displayMessage?: string): Rule => ({
  type: validatorType,
  message: displayMessage ? displayMessage : "The input is not valid",
});

const typeWithPattern = (
  validatorType: any,
  regexPattern: RegExp,
  message?: string
): Rule => ({
  type: validatorType,
  pattern: regexPattern,
  message:
    regexPattern === AvoidWhitespace
      ? "Invalid Character"
      : message
      ? message
      : "Invalid Format",
});

const dynamicRequiredValidator = (value: boolean, message?: string): Rule => {
  return value
    ? {
        required: true,
        message: message,
      }
    : { required: false };
};

export {
  required,
  max,
  min,
  type,
  typeWithPattern,
  notRequired,
  dynamicRequiredValidator,
};
