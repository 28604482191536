import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import "moment/locale/es";
import es_ES from "antd/es/locale/es_ES";
import { I18nextProvider } from "react-i18next";

import MasterLayout from "../layouts/MasterLayout";
import RouteSwitch from "../components/common/routes/RouteSwitch";
import menuConfig from "../menus/menuConfig";
import Routes from "../routes";
import i18n from "../localization/i18n";

const RootPage = (props: any) => {
  const [locele, SetLocale] = useState(enUS);

  useEffect(() => {
    setLanguage();
  });

  const setLanguage = () => {
    const lang = localStorage.getItem("locale") as any;
    if (lang) {
      i18n.changeLanguage(lang);
      const locale = lang === "en" ? enUS : es_ES;
      SetLocale(locale);
    } else {
      i18n.changeLanguage("en");
    }
  };

  const onChangeLanguage = (key: any) => {
    localStorage.setItem("locale", key.key);
    SetLocale(key.key === "en" ? enUS : es_ES);
    i18n.changeLanguage(key.key);
  };

  return (
    <ConfigProvider locale={locele}>
      <I18nextProvider i18n={i18n}>
        <MasterLayout
          onChangeLanguage={onChangeLanguage}
          {...props}
          routes={Routes}
          menus={menuConfig}
        >
          <RouteSwitch routes={props.routes} />
        </MasterLayout>
      </I18nextProvider>
    </ConfigProvider>
  );
};

export default RootPage;
