import Modal from "@app/components/common/Modal";
import { Button } from "antd";
import React from "react";

export interface CancelModalProps {
  onOk: any;
  onCancel: any;
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  visible: boolean;
}

export default ({
  title,
  content,
  onOk,
  onCancel,
  okText,
  cancelText,
  visible,
}: CancelModalProps) => {
  return (
    <Modal
      size="small"
      visible={visible}
      key="discardPopup"
      title={title}
      closable={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} type="default">
          {cancelText ? cancelText : "No"}
        </Button>,
        <Button key="yes" onClick={onOk} type="primary">
          {okText ? okText : "Yes"}
        </Button>,
      ]}
    >
      <p>{content}</p>
    </Modal>
  );
};
