import MenuConfigType from "@app/types/MenuConfigType";
import {
  ClearOutlined ,
} from "@ant-design/icons";

const retentionMenuConfig: MenuConfigType = {
  path: "/retention/",
  title: "Retention",
  key: "Retention",
  icon: ClearOutlined ,
  guard: [],
};

export default retentionMenuConfig;
