import { IFileArea, IFileAreaSettings } from "../types/fileAreaTypes";
import {
  SET_FILE_AREA_SETTINGS,
  SET_FOLDER_TREE,
  SET_HAS_COMMON_DATA,
  UPDATE_CONTEXT_MENU_DOWNLOAD,
  UPDATE_CONTEXT_MENU_STATUS,
  UPDATE_CONTEXT_MENU_ASSIGN,
  UPDATE_CONTEXT_MENU_CHECKOUT,
  UPDATE_CONTEXT_MENU_DELETE,
  UPDATE_CONTEXT_MENU_PROPETIES,
  UPDATE_CONTEXT_MENU_RE_CATEGORIZE,
  UPDATE_CONTEXT_MENU_MOVE_FILES,
  UPDATE_CONTEXT_MENU_RENAME_FILES,
  UPDATE_CONTEXT_MENU_COPY_FILES,
  UPDATE_ALLOWED_TO_CLOSE_PROPERTIES,
  UPDATE_PORTAL_FILE_UPLOAD_DETAILS,
  PORTAL_FILE_REQUEST_SENT,
  UPDATE_LOAD_GRID,
  PORTAL_FILE_UPDATE_SELECTED_REQUEST,
  FILES_UPLOADED,
  UPDATE_CONTEXT_MENU_PUBLISH,
  UPDATE_CONTEXT_MENU_UNPUBLISH,
} from "../actionTypes/fileAreaActionType";

const defaultSettings: IFileAreaSettings = {
  urlFileUpload: false,
  internalFileStatusUpdate: false,
  internalFilesEmail: false,
  internalFileSetAssignee: false,
  internalFileSetProject: false,
  internalFilesDownload: false,
  internalFilesCheckinCheckout: false,
  internalFilesRecategorize: false,
  fileAreaFolderStructure: false,
  fileAreaManageEmailSubscription: false,
  fileAreaManageTags: false,
  internalFilesAssign: false,
  internalFilesCopy: false,
  internalFilesDelete: false,
  internalFilesHistory: false,
  internalFilesMove: false,
  internalFilesRename: false,
  internalFilesUpdate: false,
  internalFilesUpload: false,
  internalFilesView: false,
  internalFilesViewAssignmentHistory: false,
  internalFilesViewCheckoutHistory: false,
  internalFilesViewProperties: false,
  internalFilesViewVersionHistory: false,
  portalFilesView: false,
  internalFilesPublishUnPublish: false,
};

export const initialState: IFileArea = {
  fileAreaSettings: defaultSettings,
  folderTree: { siteId: "", siteName: "", siteStatusId: -1, folders: [] },
  hasCommonData: false,
  download: false,
  status: false,
  assign: false,
  checkout: false,
  publish: false,
  unpublish: false,
  loadGrid: false,
  propeties: false,
  deleteFiles: false,
  reCategorize: false,
  moveFiles: false,
  renameFiles: false,
  copySelectedFiles:false,
  filesUploaded: false,
  allowedToCloseProperties: false,
  portalFilesUpload: {
    requestId: null,
    securityKey: null,
  },

  portalFileRequestSent: false,
  portalFilesSelectedRequest: { action: null, requestId: null },
};

export const fileAreaReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FILE_AREA_SETTINGS:
      return {
        ...state,
        fileAreaSettings: { ...state.fileAreaSettings, ...action.payload },
      };
    case SET_FOLDER_TREE:
      return { ...state, folderTree: action.payload };
    case SET_HAS_COMMON_DATA:
      return { ...state, hasCommonData: action.payload };
    case UPDATE_CONTEXT_MENU_DOWNLOAD:
      return { ...state, download: action.payload };
    case UPDATE_CONTEXT_MENU_STATUS:
      return { ...state, status: action.payload };
    case UPDATE_CONTEXT_MENU_ASSIGN:
      return { ...state, assign: action.payload };
    case UPDATE_CONTEXT_MENU_CHECKOUT:
      return { ...state, checkout: action.payload };
    case UPDATE_CONTEXT_MENU_PUBLISH:
      return { ...state, publish: action.payload };
    case UPDATE_CONTEXT_MENU_UNPUBLISH:
      return { ...state, unpublish: action.payload };
    case UPDATE_LOAD_GRID:
      return { ...state, loadGrid: action.payload };
    case UPDATE_CONTEXT_MENU_PROPETIES:
      return { ...state, propeties: action.payload };
    case UPDATE_CONTEXT_MENU_DELETE:
      return { ...state, deleteFiles: action.payload };
    case UPDATE_CONTEXT_MENU_RE_CATEGORIZE:
      return { ...state, reCategorize: action.payload };   
    case UPDATE_CONTEXT_MENU_MOVE_FILES:
      return { ...state, moveFiles: action.payload };
    case UPDATE_CONTEXT_MENU_RENAME_FILES:
      return { ...state, renameFiles: action.payload };
    case UPDATE_CONTEXT_MENU_COPY_FILES:
      return { ...state, copySelectedFiles: action.payload };
    case UPDATE_ALLOWED_TO_CLOSE_PROPERTIES:
      return { ...state, allowedToCloseProperties: action.payload };
    case UPDATE_PORTAL_FILE_UPLOAD_DETAILS:
      return { ...state, portalFilesUpload: action.payload };
    case PORTAL_FILE_REQUEST_SENT:
      return { ...state, portalFileRequestSent: action.payload };
    case PORTAL_FILE_UPDATE_SELECTED_REQUEST:
      return { ...state, portalFilesSelectedRequest: action.payload };
    case FILES_UPLOADED:
      return { ...state, filesUploaded: action.payload };
    default:
      return state;
  }
};
