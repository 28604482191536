import React from 'react';
import RouteConfigType from '@app/types/RouteConfigType';
import RouteSwitch from '@app/components/common/routes/RouteSwitch';

const FileAreas = React.lazy(() => import('@pages/FileAreas'));

const FileArea = React.lazy(() => import('@pages/FileAreas/FileArea'));

const PortalFiles = React.lazy(() => import('@pages/FileAreas/PortalFiles'));

const PortalFilesHistory = React.lazy(() => import('@pages/FileAreas/PortalFiles/PortalFilesHistory'));
const History = React.lazy(() => import('@pages/FileAreas/FileArea/History'));

const fileAreaRoutes: RouteConfigType[] = [
  {
    component: FileAreas,
    path: '/filearea',
    title: 'File Areas',
    exact: true,
    guard: [],
    useInBreadcrumbs: false,
  },
  {
    component: History,
    path: '/filearea/:channelId/:siteId/:siteName/history/:fileId',
    title: 'History',
    exact: false,
    useInBreadcrumbs: false,
    guard: [],
  },
  {
    component: RouteSwitch,
    path: '/filearea/:channelId/:siteId/:siteName',
    title: `Site`,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    dynamicBreadcrumbIndex: 0,
    routes: [
      {
        component: RouteSwitch,
        path: '/filearea/:channelId/:siteId/:siteName/portal',
        title: 'Portal',
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes: [
          {
            component: PortalFilesHistory,
            path: '/filearea/:channelId/:siteId/:siteName/portal/history',
            title: 'History',
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
          },
          {
            component: PortalFiles,
            path: '/filearea/:channelId/:siteId/:siteName/portal',
            title: '',
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
          },
        ],
      },
      {
        component: FileArea,
        path: '/filearea/:channelId/:siteId/:siteName',
        title: '',
        exact: true,
        guard: [],
        useInBreadcrumbs: true,
      },
    ],
  },
];

export default fileAreaRoutes;
