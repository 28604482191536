import configs from "./endpoints";
import { store } from "../../../";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";

export const getApiUrl = (
  url: string,
  serviceType?: OperationalServiceTypes
): string => {
  if (!serviceType) return `${url}`;
  const state = store.getState();
  const { endPoints, tenant } = state.auth;
  let baseURL = endPoints?.find((e) => e.serviceType === serviceType)
    ?.entrypointAddress;
  if (baseURL) return `${baseURL}/${tenant}/${url}`;
  return url;
};

export default configs;
