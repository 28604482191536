import LoggerInterface from "./interfaces/LoggerInterface";
import Config from "../config";
class Logger implements LoggerInterface {
  protected logger: any;
  protected logLevel: string;
  protected logHierarchy: any = {
    verbose: 0,
    debug: 1,
    info: 2,
    warn: 3,
    error: 4,
  };

  constructor() {
    this.logLevel = Config.logLevel.toLocaleLowerCase();
  }

  public setLogLevel = (logLevel: string): void => {
    this.logLevel = logLevel.toLocaleLowerCase();
  };

  public getLogLevel = (): string => {
    return this.logLevel;
  };

  public debug = (
    application: string,
    component: string,
    message: any
  ): void => {
    if (this.validateLogHierarchy("debug")) {
      this.message(application, component, "debug", message);
    }
  };

  public verbose = (
    application: string,
    component: string,
    message: any
  ): void => {
    if (this.validateLogHierarchy("verbose")) {
      this.message(application, component, "verbose", message);
    }
  };

  public info = (
    application: string,
    component: string,
    message: any
  ): void => {
    if (this.validateLogHierarchy("info")) {
      this.message(application, component, "info", message);
    }
  };

  public warn = (
    application: string,
    component: string,
    message: any
  ): void => {
    if (this.validateLogHierarchy("warn")) {
      this.message(application, component, "warn", message);
    }
  };

  public error = (
    application: string,
    component: string,
    message: any
  ): void => {
    if (this.validateLogHierarchy("error")) {
      this.message(application, component, "error", message);
    }
  };

  private validateLogHierarchy(logLevel: string): boolean {
    return this.logHierarchy[this.logLevel] <= this.logHierarchy[logLevel];
  }

  private readonly message = (
    application: string,
    component: string,
    level: string,
    message: any
  ) => {
    if (typeof message !== "string") {
      message = JSON.stringify(message);
    }
    const colorMap: { [key: string]: string } = {
      verbose: "\x1b[90m",  // Gray
      debug: "\x1b[32m",    // Green
      info: "\x1b[34m",     // Blue
      warn: "\x1b[33m",     // Yellow
      error: "\x1b[31m",    // Red
    };

    const resetColor = "\x1b[0m";
    const color = colorMap[level] || resetColor;
    const maskedMessage = `${color}${level} : ${application}: ${component}: ${message}${resetColor}`;

    console.log(maskedMessage);
  };
}

export default new Logger();
