import MenuConfigType from "../types/MenuConfigType";
import onboardingMenuConfig from "./onboardingMenuConfig";
import fileAreaMenuConfig from "./fileAreaMenuConfig";
import masterDataMenuConfig from "./masterDataMenuConfig";
import dashboardConfig from "./dashboardConfig";
import userManagementMenuConfig from "./userManagementMenuConfig";
import templateMenuConfig from "@app/menus/templateMenuConfig";
import retentionMenuConfig from "@app/menus/retentionMenuConfig";
import documentMaintenanceMenuConfig from "@app/menus/documentMaintenanceMenuConfig";

const menuConfig: MenuConfigType[] = [
  dashboardConfig,
  onboardingMenuConfig,
  fileAreaMenuConfig,
  masterDataMenuConfig,
  templateMenuConfig,
  userManagementMenuConfig,
  retentionMenuConfig,
  documentMaintenanceMenuConfig
];

export default menuConfig;
