import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useLocation, matchPath, useHistory } from "react-router-dom";

import MenuConfigType from "../../types/MenuConfigType";
import logger from "../../utils/logger";
import styles from "./index.module.less";

const { Sider } = Layout;
const { SubMenu } = Menu;

type PropTypes = {
  menu: MenuConfigType[];
};

const SideMenu: React.FC<PropTypes> = ({ menu }) => {
  const [collapse, setCollapse] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const [hamburgerButtonClicked, setHamburgerButtonClicked] = useState(false);
  const [selectedRoutes, setSelectedRoutes] = useState(
    getMatchedPaths(menu, location.pathname)
  );
  const MENU_PAGE_Y = 340;

  useEffect(() => {
    const listener = history.listen(() => {
      logger.debug(
        "common",
        "SideMenu",
        `route change identified : ${location.pathname}`
      );
      setSelectedRoutes(getMatchedPaths(menu, location.pathname));
    });

    return () => {
      listener();
    };
  }, [history, menu, location]);

  const _toggle = () => {
    collapse ? setCollapse(false) : setCollapse(true);
    setHamburgerButtonClicked(!hamburgerButtonClicked);
  };

  const _redirect = (path: string) => {
    history.push(path);
  };

  const renderSubMenu = (item: MenuConfigType) => {
    return (
      item.children &&
      item.privileged && (
        <SubMenu
          data-path={item.path}
          className={styles.yjSubMenuItem}
          icon={
            typeof item.icon === "string" ? (
              <div className={item.icon} />
            ) : (
              <item.icon />
            )
          }
          key={_makeRouteHash(item.path)}
          title={item.title}
        >
          {_menuItems(item.children)}
        </SubMenu>
      )
    );
  };

  const renderMenuItem = (item: MenuConfigType) => {
    return (
      item.privileged && (
        <Menu.Item
            data-path={item.path}
          className={styles.yjMenuItem}
          onClick={() => _redirect(item.path)}
          icon={
            typeof item.icon === "string" ? (
              <div className={item.icon} />
            ) : (
              <item.icon />
            )
          }
          key={_makeRouteHash(item.path)}
        >
          {item.title}
        </Menu.Item>
      )
    );
  };

  const _menuItems = (data: MenuConfigType[]) => {
    return data.map((item) =>
      item.children && item.children.length > 0
        ? renderSubMenu(item)
        : renderMenuItem(item)
    );
  };

  return (
    <Sider
      onMouseLeave={() => {
        if (!collapse && !hamburgerButtonClicked) {
          setCollapse(true);
        }
      }}
      onMouseEnter={(e) => {
        if (e.pageY > MENU_PAGE_Y && collapse) {
          setCollapse(false);
        }
      }}
      trigger={null}
      collapsible
      collapsed={collapse}
      breakpoint="lg"
      collapsedWidth="70"
      className={styles.yjSideNav}
      width="300"
    >
      {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: styles.yjSideTrigger,
        onClick: _toggle,
      })}
      <div className={"yjSideMenuWrapper"}>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={selectedRoutes}
          className={styles.yjSideMenu}
        >
          {_menuItems(menu)}
        </Menu>
         {/*<div  style={{color: '#666', padding: '1rem'}}>{process.env.REACT_APP_VERSION}</div>*/}
      </div>
    </Sider>
  );
};

const getMatchedPaths = (routes: MenuConfigType[], location: string) => {
  const matchedPathsArray: string[] = [];
  const generateFlatPathArray = (
    routeValues: MenuConfigType[],
    locationValue: string
  ) => {
    if (routeValues) {
      for (const route of routeValues) {
        const matchedRoute = matchPath(locationValue, {
          path: route.path,
          exact: route.exact,
        });
        if (matchedRoute) {
          matchedPathsArray.push(_makeRouteHash(route.path));
        }
        if (matchedRoute && route.children) {
          generateFlatPathArray(route.children, locationValue);
        }
      }
    }
  };

  generateFlatPathArray(routes, location);
  return matchedPathsArray;
};

const _makeRouteHash = (key: string) => {
  return key.split("/").join("");
};

export default SideMenu;
