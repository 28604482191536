/**
 * This hook will be called when a route changes
 *
 * @param message
 * @param when: prompt will hide and show according to this conditon
 */

import { useHistory } from "react-router-dom";
import { useRef, useEffect } from "react";

export const usePrompt = (message: any, when: any) => {
  const history = useHistory();
  const unblock = useRef(null) as any;

  useEffect(() => {
    if (when) {
      unblock.current = history.block(message);
    } else {
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, history, message]);
};
