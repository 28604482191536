import { combineReducers } from "redux";
import { RootState } from "./state";
import { authReducer } from "./authReducer";
import licenseMgtReducer from "./licenseReducer";
import verticalReducer from "./verticalReducer";
import { functionalFlowReducer } from "./functionalFlowReducer";
import fileDetailsReducer from "./fileDetailsReducer";
import { gridReducer } from "./gridReducers";
import { fileAreaReducer } from "./fileAreaReducer";
import { userManagementReducers } from "./userManagementReducers";
import { configurationReducer } from "./configurationReducer";
import firmReducer from "./firmReducer";
import { templateReducer } from "@app/redux/reducers/templateReducer";

export const rootReducer = combineReducers<RootState>({
  auth: authReducer,
  licenseManagement: licenseMgtReducer,
  functionalFlow: functionalFlowReducer,
  vertical: verticalReducer,
  fileDetails: fileDetailsReducer,
  grid: gridReducer,
  fileArea: fileAreaReducer,
  template: templateReducer,
  userManagement: userManagementReducers,
  configuration: configurationReducer,
  firm: firmReducer,
});
