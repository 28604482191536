import { Button } from "antd";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import React from "react";
import Modal from "@app/components/common/Modal";

export const PromptOnload = (isBlocking: any, isSaving: any) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isBlocking
  );

  return (
    <Modal
      key="discardPopup"
      size="small"
      title={"Leave Page"}
      visible={showPrompt as boolean}
      onOk={confirmNavigation as () => void}
      onCancel={cancelNavigation as () => void}
      closable={isSaving}
      maskClosable={!isSaving}
      footer={[
        <Button
          key="cancel"
          onClick={cancelNavigation as () => void}
          type="default"
        >
          Cancel
        </Button>,
        <Button
          key="yes"
          onClick={confirmNavigation as () => void}
          type="primary"
        >
          Leave
        </Button>,
      ]}
    >
      <p>
        Your details will not be saved. Are you sure you want to leave this
        page?
      </p>
    </Modal>
  );
};
