import MenuConfigType from "@app/types/MenuConfigType";
import {
  ClusterOutlined,
} from "@ant-design/icons";

const templateMenuConfig: MenuConfigType = {
  path: "/template/",
  title: "Template",
  key: "Template",
  icon: ClusterOutlined,
  guard: [],
};

export default templateMenuConfig;
