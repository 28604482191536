const PositiveNumberPattern = /^[0-9\b]+$/;
const PhoneNoPattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const WebsitePattern = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
const WebsitePatternWithExtensions = /^(?:http(s)?:\/\/)?[\w.-]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
const AvoidWhitespace = /[^-\s]/;
const EmailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const FileNameCharacters   = /^[a-zA-Z0-9!^@.\(\)~_\\\-+=',\$\?"\/>£\s]+$/;

export {
  PositiveNumberPattern,
  PhoneNoPattern,
  WebsitePattern,
  WebsitePatternWithExtensions,
  AvoidWhitespace,
  EmailPattern,
  FileNameCharacters
};
