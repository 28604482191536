import React from "react";

import RouteSwitch from "@app/components/common/routes/RouteSwitch";
import RouteConfigType from "@app/types/RouteConfigType";

const TemplatePage = React.lazy(() => import("@pages/Template"));


const templateRoutes: RouteConfigType[] = [
  {
    component: RouteSwitch,
    path: "/template",
    title: "Template",
    exact: false,
    guard: [],
    useInBreadcrumbs: false,
    routes: [
      {
        component: TemplatePage,
        path: "/template",
        title: "Manage Template",
        exact: true,
        guard: [],
        useInBreadcrumbs: true,
      },
    ],
  },
];

export default templateRoutes;
