import http from "../utils/http";
import HTTPResponse from "../utils/http/interfaces/HttpResponse";
import httpVerbs from "../utils/http/httpVerbs";
import { getMultipleQueryUrl, getParameterizedUrl } from "@app/utils";
import { CreateFilterTemplateRequest } from "@app/types/filterTemplateTypes";
import config from "@app/utils/config";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";

export type Columns = {
  key: string;
  displayValue: string;
  dataType: string;
  defaultVisible: boolean;
  sortable: boolean;
  filterType: string;
  filterData?: any;
};

export const getColumns = (
    endpoint: string,
    type?: string,
    columnQueryParameters?: [{ value: any; key: string }]
): Promise<HTTPResponse<Columns[]>> => {
  const queryParameters: any = {
    type,
  };

  if (columnQueryParameters) {
    columnQueryParameters.forEach((query: any) => {
      queryParameters[query.key] = query.value;
    });
  }

  return http({
    method: httpVerbs.GET,
    params: queryParameters,

    url: `${endpoint}/columns`,
  });
};

export const getRecords = (
  endpoint: string,
  options: any,
  searchQueryParameters?: []
): Promise<HTTPResponse<any>> => {
  const mappedOptions: any = {
    limit: options.pagination.pageSize,
    offset: options.pagination.current - 1,
  };
  if (options.sorter && options.sorter.order) {
    mappedOptions.sort = `${options.sorter.key}:${
      options.sorter.order === "ascend" ? "asc" : "desc"
    }`;
  }
  if (options.columns) {
    mappedOptions.optColumns = options.columns.join(",");
  }

  if (searchQueryParameters) {
    console.log({ searchQueryParameters });
    searchQueryParameters.forEach((query: any) => {
      mappedOptions[query.key] = query.value;
    });
  }

  const containFilters =
    options.filters && !!Object.keys(options.filters).length;
  const requestObject = {
    method: containFilters ? httpVerbs.POST : httpVerbs.GET,
    url: containFilters ? `${endpoint}/search` : `${endpoint}/`,
    params: mappedOptions,
    headers: {
      "content-type": "application/json",
    },
    data: options.filters,
  };

  if (!options.filters || Object.keys(options.filters).length === 0) {
    delete requestObject.data;
  }

  return http(requestObject);
};

export const getAutocompleteOptions = (
  endpoint: string,
  column: string,
  searchString: string,
  searchFieldParameters?: any[]
): Promise<HTTPResponse<any>> => {
  const queryParameters: any = {
    query: searchString,
    limit: 5,
  };

  if (searchFieldParameters) {
    searchFieldParameters.forEach((query: any) => {
      queryParameters[query.key] = query.value;
    });
  }

  return http({
    method: httpVerbs.GET,
    url: `${endpoint}/fields/${column}`,
    params: queryParameters,
  });
};

export const getOptions = (
  endpoint: string,
  column: string
): Promise<HTTPResponse<any>> => {
  return new Promise((resolve) => {
    const _data = [
      { value: "Test Option 1" },
      { value: "Test Option 2" },
      { value: "Test Option 3" },
      { value: "Test Option 4" },
    ];
    resolve({
      data: _data,
      status: "SUCCESS",
    });
  });
};

export const getFilterTemplates = (
  gridKey: string,
  groupedValue?: string
): Promise<HTTPResponse<any>> => {
  let multipleQueryArray = [{ key: "gridKey", value: gridKey }];
  if (groupedValue) {
    multipleQueryArray.push({ key: "groupedValue", value: groupedValue });
  }
  return http({
    method: httpVerbs.GET,
    url: getMultipleQueryUrl(
      config.api[OperationalServiceTypes.BffService].filter,
      multipleQueryArray
    ),
  });
};

export const createFilterTemplate = (
  createFilterRequest: CreateFilterTemplateRequest
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.POST,
    url: config.api[OperationalServiceTypes.BffService].filter,
    data: createFilterRequest,
  });
};

export const modifyFilterTemplate = (
  templateId: string,
  createFilterRequest: CreateFilterTemplateRequest
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.PUT,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.BffService].filterById,
      templateId
    ),
    data: createFilterRequest,
  });
};

export const deleteFilterTemplate = (
  filterId: string
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.DELETE,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.BffService].filterById,
      filterId
    ),
  });
};
