export const NAME = 'LICENSE_MGMT';

const DATA_FETCH_SUCESSFUL = `${NAME}/DATA_FETCH_SUCESSFUL`;
const OPTIONS_FETCH_SUCCESSFUL = `${NAME}/OPTIONS_FETCH_SUCCESSFUL`;
const SET_LICENSE_DETAILS = `${NAME}/SET_LICENSE_DETAILS`;
const HAS_ERRORED = `${NAME}/HAS_ERRORED`;
const SET_COMPLIANCES = `${NAME}/SET_COMPLIANCES`;
const SET_STATUSES = `${NAME}/SET_STATUSES`;
const SET_VERTICALS = `${NAME}/SET_VERTICALS`;
const SET_STORAGES = `${NAME}/SET_STORAGES`;
const SET_USER_COUNTS = `${NAME}/SET_USER_COUNTS`;
const SET_SUPPORT_LEVELS = `${NAME}/SET_SUPPORT_LEVELS`;
const SET_INTEGRATIONS = `${NAME}/SET_INTEGRATIONS`;
const SAVE_SUCCESSED = `${NAME}/SAVE_SUCCESSED`;

export default {
    DATA_FETCH_SUCESSFUL,
    SET_LICENSE_DETAILS,
    HAS_ERRORED,
    SET_COMPLIANCES,
    SET_STATUSES,
    SET_VERTICALS,
    SET_STORAGES,
    SET_USER_COUNTS,
    SET_SUPPORT_LEVELS,
    SET_INTEGRATIONS,
    OPTIONS_FETCH_SUCCESSFUL,
    SAVE_SUCCESSED,
};
