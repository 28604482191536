import React from "react";

import RouteSwitch from "@app/components/common/routes/RouteSwitch";
import RouteConfigType from "@app/types/RouteConfigType";
import AddEditChannels from "@pages/MasterData/ManageChannels/AddEditChannels";

const ManageChannels = React.lazy(() =>
  import("@pages/MasterData/ManageChannels")
);

const AccountsManagement = React.lazy(() =>
  import("@pages/MasterData/AccountsManagement")
);
const AddEditSites = React.lazy(() =>
  import("@pages/../features/Site/AddEditSites")
);
const ManageSites = React.lazy(() => import("@pages/MasterData/ManageSites"));

const masterDataRoutes: RouteConfigType[] = [
  {
    component: RouteSwitch,
    path: "/master-data",
    title: "Master Data",
    exact: false,
    guard: [],
    useInBreadcrumbs: false,
    routes: [
      {
        component: RouteSwitch,
        path: "/master-data/manage-sites",
        title: "Manage Sites",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes: [
          {
            component: AddEditSites,
            path: "/master-data/manage-sites/create",
            title: "Create Site",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
            disableBreadcrum: true,
          },
          {
            component: AddEditSites,
            path: "/master-data/manage-sites/edit/:id",
            title: "Update Site",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
          },
          {
            component: ManageSites,
            path: "/master-data/manage-sites",
            title: "Manage Sites",
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
        ],
      },
      {
        component: RouteSwitch,
        path: "/master-data/manage-channels",
        title: "Manage Office",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes: [
          {
            component: AddEditChannels,
            path: "/master-data/manage-channels/create",
            title: "Create Office",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
            disableBreadcrum: true,
          },
          {
            component: AddEditChannels,
            path: "/master-data/manage-channels/edit/:id",
            title: "Update Office",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
          },
          {
            component: ManageChannels,
            path: "/master-data/manage-channels",
            title: "Manage Office",
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
        ],
      },
      {
        component: RouteSwitch,
        path: "/master-data/account-management",
        title: "Account Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes: [
          {
            component: AccountsManagement,
            path: "/master-data/account-management",
            title: "Account Management",
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default masterDataRoutes;
