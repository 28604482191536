import {
  ADD_GRID_FILTER,
  APPLY_SAVED_FILTER,
  CLEAR_ALL_FILTERS,
  FILTER_CLOUD_UPDATED,
  REMOVE_GRID_FILTER,
  UPDATE_COLUMN_PARAMETERS,
  UPDATE_COLUMNS,
  UPDATE_FILTERS,
  UPDATE_SAVED_FILTER_TEMPLATES,
  UPDATE_SEARCH_PARAMETERS,
  UPDATE_SINGLE_COLUMN,
  FILTER_TEMPLATE_SAVED,
  REMOVE_GRID_FILTERS,
  UPDATE_PRE_APPLIED_FILTERS,
  SELECT_SAVED_FILTER,
  HIDE_SAVE_NEW_FILTER,
  REMOVE_SELECT_SAVED_FILTER,
  UPDATE_GRID_HAS_UPDATES,
} from "../actionTypes/gridActionTypes";
import { IGridView } from "../types/gridView";
import { GenericFilter } from "@app/components/GenericDataTable/types";
import { setTableColumns } from "@app/components/GenericDataTable/DataTableContext/persistColumns";

export const initialState: IGridView = {
  tableKey: "",
  columns: [Object],
  selectedElement: { name: "", checked: true, multiple: false },
  filters: [],
  showFilter: false,
  selected: false,
  columnQueryParameters: [],
  searchQueryParameters: [],
  gridFilters: [],
  showFilterSaveButton: false,
  savedFilters: [],
  filter_template_saved: false,
  preAppliedFilters: [],
  hasUpdates: false,
};

export const gridReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_SINGLE_COLUMN:
      return {
        ...state,
        selectedElement: action.payload.selectedElement,
        tableKey: action.payload.tableKey,
        selected: action.payload.selected,
      };

    case UPDATE_COLUMNS:
      setTableColumns(action.payload.tableKey, [...action.payload.columns]);
      return {
        ...state,
        columns: action.payload.columns,
        selectedElement: action.payload.selectedElement,
        selected: action.payload.selected,
      };

    case UPDATE_COLUMN_PARAMETERS:
      return {
        ...state,
        columnQueryParameters: action.payload,
      };

    case UPDATE_SEARCH_PARAMETERS:
      return {
        ...state,
        searchQueryParameters: action.payload,
      };

    case UPDATE_FILTERS:
      return {
        ...state,
        showFilter: action.payload,
      };

    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filters: [],
        gridFilters: [],
        showFilterSaveButton: false,
      };

    case ADD_GRID_FILTER:
      const filters = [...state.gridFilters, action.payload];
      const showFilter = filters.length > 0;
      return {
        ...state,
        gridFilters: filters,
        showFilterSaveButton: showFilter,
      };
    case REMOVE_GRID_FILTER:
      const filterToBeRemoved = action.payload;
      const filtersAfterRemoveGivenFilter = (state.gridFilters as []).filter(
        (filter: GenericFilter) => {
          return !(
            filter.key === filterToBeRemoved.key &&
            filter.value === filterToBeRemoved.value
          );
        }
      );
      return {
        ...state,
        gridFilters: filtersAfterRemoveGivenFilter,
        showFilterSaveButton: filtersAfterRemoveGivenFilter.length !== 0,
      };

    case REMOVE_GRID_FILTERS:
      const filterkeysToBeRemoved = action.payload;
      const filtersAfterRemoveGivenFilters = (state.gridFilters as []).filter(
        (filter: GenericFilter) => {
          return !filterkeysToBeRemoved.includes(filter.key);
        }
      );
      return {
        ...state,
        gridFilters: filtersAfterRemoveGivenFilters,
        showFilterSaveButton: filtersAfterRemoveGivenFilters.length !== 0,
      };

    case UPDATE_SAVED_FILTER_TEMPLATES:
      return {
        ...state,
        savedFilters: action.payload,
      };

    case FILTER_CLOUD_UPDATED:
      return {
        ...state,
        gridFilters: action.payload,
      };

    case APPLY_SAVED_FILTER:
      const gridFilters = action.payload.gridFilterTemplates;
      return {
        ...state,
        columns: action.payload.filterColumns,
        gridFilters: [...gridFilters],
        showFilter: true,
        showFilterSaveButton: true,
        selected: action.payload.selected,
        selectedElement: action.payload.selectedElement,
      };

    case FILTER_TEMPLATE_SAVED:
      return {
        ...state,
        filter_template_saved: action.payload,
      };

    case UPDATE_PRE_APPLIED_FILTERS:
      return {
        ...state,
        preAppliedFilters: action.payload,
      };
    case SELECT_SAVED_FILTER:
      return {
        ...state,
        selectSavedFilter: action.payload,
      };
    case HIDE_SAVE_NEW_FILTER:
      return {
        ...state,
        showFilterSaveButton: false,
      };
    case REMOVE_SELECT_SAVED_FILTER:
      return {
        ...state,
        selectSavedFilter: undefined,
      };
    case UPDATE_GRID_HAS_UPDATES:
      return {
        ...state,
        hasUpdates: action.payload,
      };
    default:
      return state;
  }
};
