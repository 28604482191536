import {
  FolderOpenOutlined,
  PlayCircleOutlined
} from "@ant-design/icons";

import MenuConfigType from "@app/types/MenuConfigType";

export const documentMaintenanceMenuConfig: MenuConfigType = {
  path: "/document-maintenance/z",
  title: "Document Maintenance 3",
  key: "DocumentMaintenance",
  icon: FolderOpenOutlined,
  guard: [],
  children: [
    {
      path: "/document-maintenance/auto-filing-setting",
      title: "AutoFiling Settings 3",
      key: "AutoFilingSettings",
      icon: PlayCircleOutlined,
      guard: [],
    },
    {
      path: "/document-maintenance/auto-filing-setting/logs",
      title: "Logs",
      key: "AutoFilingSettingsLogs",
      icon: PlayCircleOutlined,
      guard: [],
    }
  ],
};

export default documentMaintenanceMenuConfig;
