import { Module } from "@app/types/Modules";

export const NAME = 'VERTICAL';

export const SET_MODULES = `${NAME}/SET_MODULES`;
export const MODULES_FETCH_SUCCESSFUL = `${NAME}/MODULES_FETCH_SUCCESSFUL`;

export interface SetModulesAction {
    type: typeof SET_MODULES,
    verticalId: number,
    payload: [Module]
}

export interface IsModulesFetchedAction {
    type: typeof MODULES_FETCH_SUCCESSFUL,
    isFetched: boolean
}

export type VerticalActionTypes = SetModulesAction & IsModulesFetchedAction;

