export default {
  apiRoot: "https://demo.identityserver.io/api/",
  tokenKey: "AP_AUTH_TOKEN",
  tenatKey: "AP_TENAT",
  redirectUri: window.location.origin + "/login/callback",
  logoutUrl: window.location.origin + "/logout",
  scopes: ["openid", "profile", "tenancy"],
  postSignedInRedirect: "/filearea",
  baseUrlPlaceholder: "https://yjdevweb.conarc.net",
};

export const SITE_STATUS:  { [key: number]: string } = {
  1: 'ACTIVE',
  2: 'Inactive',
  3: 'Active_LH',
  4: 'Inactive_LH'
}

export const PERMISSIONS = {
  FILE_AREA_UPLOADER: 'FILE_AREA_UPLOADER',
  FILE_AREA_DOWNLOAD: 'FILE_AREA_DOWNLOAD',
  FILE_AREA_FILE_EDIT: 'FILE_AREA_FILE_EDIT',
  FILE_AREA_OTHER_OPTIONS: 'FILE_AREA_OTHER_OPTIONS',
  FILE_AREA_CLIENT_PORTAL_FILE_EDIT: 'FILE_AREA_CLIENT_PORTAL_FILE_EDIT',
}
