import React from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import RouteSwitch from "../../components/common/routes/RouteSwitch";
import MainRoute from "../../routes";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import constants from "@app/constants";
import config from "@app/utils/config";
import logger from '@app/utils/logger';

const App: React.FC<{ authUrl: string }> = ({ authUrl }) => {
  logger.info('App', 'REACT_APP_VERSION', (window as any)._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION);
  logger.info('App', 'REACT_APP_PIPELINE_VERSION', (window as any)._env_?.REACT_APP_PIPELINE_VERSION);

  let history = useHistory();

  const oktaAuth = new OktaAuth({
    issuer: authUrl,
    clientId: config.clientId,
    redirectUri: constants.redirectUri,
    postLogoutRedirectUri: constants.logoutUrl,
    scopes: constants.scopes,
    tokenManager: {
      autoRenew: true,  // Enables auto-renewal of tokens
      secure: true,
      storageKey: constants.tokenKey,
    },
  });

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: any) => {
    history.replace(
      toRelativeUrl(
        originalUri || constants.postSignedInRedirect,
        window.location.origin
      )
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <RouteSwitch routes={MainRoute} />
    </Security>
  );
};

const MainApp: React.FC<{ authUrl: string }> = ({ authUrl }) => (
  <BrowserRouter>
    <App authUrl={authUrl} />
  </BrowserRouter>
);

export default MainApp;
