import CommonLayout from "@app/layouts/CommonLayout";
import { LoginCallback } from "@okta/okta-react";
import { Skeleton } from "antd";
import React from "react";
import AppError from "../AppError";

const CustomCallback: React.FC = () => {
  const renderError = (props: any) => {
    const { error } = props;
    return (
      <CommonLayout>
        <AppError
          message={error.errorSummary}
          showContactAdmin
          autoLogoutTime={10}
        />
      </CommonLayout>
    );
  };

  return (
    <LoginCallback
      loadingElement={<Skeleton active />}
      errorComponent={renderError}
    />
  );
};

export default CustomCallback;
