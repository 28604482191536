import HTTPResponse from "../utils/http/interfaces/HttpResponse";
import http from "../utils/http";
import httpVerbs from "../utils/http/httpVerbs";
import config, { getApiUrl } from "../utils/config";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";
import { FirmLogoAndNameResponseType } from "@app/types/ResponseTypes";

export const getCountries = (): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getApiUrl(config.api.organizationAPI.countries),
  });
};

export const getFirmNameAndLogo = (): Promise<
  HTTPResponse<FirmLogoAndNameResponseType>
> => {
  return http({
    method: httpVerbs.GET,
    url: getApiUrl(
      config.api[OperationalServiceTypes.DashboardService].firmLogoAndName
    ),
  });
};
