export const UPDATE_COLUMNS = "@@GRID_VIEW/UPDATE_COLUMNS";
export const UPDATE_SINGLE_COLUMN = "@@GRID_VIEW/UPDATE_SINGLE_COLUMN";
export const UPDATE_FILTERS = "@@GRID_VIEW/UPDATE_FILTERS";
export const CLEAR_ALL_FILTERS = "@@GRID_VIEW/CLEAR_ALL_FILTERS";
export const GET_CURRENT_FILTERS = "@@GRID_VIEW/GET_CURRENT_FILTERS";
export const GET_SAVED_FILTERS = "@@GRID_VIEW/GET_SAVED_FILTERS";
export const UPDATE_SAVED_FILTER_TEMPLATES =
  "@@GRID_VIEW/UPDATE_SAVED_FILTER_TEMPLATES";
export const ADD_GRID_FILTER = "@@GRID_VIEW/ADD_GRID_FILTER";
export const REMOVE_GRID_FILTER = "@@GRID_VIEW/REMOVE_GRID_FILTER";
export const APPLY_SAVED_FILTER = "@@GRID_VIEW/APPLY_SAVED_FILTER";
export const UPDATE_COLUMN_PARAMETERS = "@@GRID_VIEW/UPDATE_COLUMN_PARAMETERS";
export const UPDATE_SEARCH_PARAMETERS = "@@GRID_VIEW/UPDATE_SEARCH_PARAMETERS";
export const MANAGE_FILTERS_DELETE_FILTER_TEMPLATE =
  "@@GRID_VIEW/MANAGE_FILTERS_DELETE_FILTER_TEMPLATE";
export const MANAGE_FILTERS_EDIT_FILTER_TEMPLATE =
  "@@GRID_VIEW/MANAGE_FILTERS_EDIT_FILTER_TEMPLATE";
export const FILTER_CLOUD_UPDATED = "@@GRID_VIEW/FILTER_CLOUD_UPDATED";
export const FILTER_TEMPLATE_SAVED = "@@GRID_VIEW/FILTER_TEMPLATE_SAVED";
export const REMOVE_GRID_FILTERS = "@@GRID_VIEW/REMOVE_GRID_FILTERS";
export const UPDATE_PRE_APPLIED_FILTERS =
  "@@GRID_VIEW/UPDATE_PRE_APPLIED_FILTERS";
export const SELECT_SAVED_FILTER = "@@GRID_VIEW/SELECT_SAVED_FILTER";
export const HIDE_SAVE_NEW_FILTER = "@@GRID_VIEW/HIDE_SAVE_NEW_FILTER";
export const REMOVE_SELECT_SAVED_FILTER =
  "@@GRID_VIEW/REMOVE_SELECT_SAVED_FILTER";
export const UPDATE_GRID_HAS_UPDATES = "@@GRID_VIEW/UPDATE_GRID_HAS_UPDATES";
