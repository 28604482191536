const LOCAL_KEY = "table_data";

const getTableData = () => {
  const data = localStorage.getItem(LOCAL_KEY);
  return data ? JSON.parse(data) : null;
};

export const getTableColumns = (tableKey?: string): any[] | null => {
  if (!tableKey) {
    return null;
  }
  return getTableData()?.[tableKey];
};

export const setTableColumns = (tableKey: string, columns: any) => {
  if (!tableKey) {
    return;
  }
  let data = getTableData();
  data = data ? data : {};
  data = {
    ...data,
    [tableKey]: columns.map((i: any) => {
      return { key: i.key, selected: i.selected };
    }),
  };
  console.info('setItem',LOCAL_KEY, data);

  localStorage.setItem(LOCAL_KEY, JSON.stringify(data));
};

export const findByKey = (tableKey: string | undefined, key: string) => {
  const data = getTableColumns(tableKey);
  if (!data) {
    return null;
  }
  const findData = data.find((o) => o.key === key);
  return findData ? findData : {};
};
