import MenuConfigType from '@app/types/MenuConfigType';
import {
    DeploymentUnitOutlined,
    ToolOutlined,
    DesktopOutlined,
    AuditOutlined,
    InteractionOutlined,
    BankOutlined,
    ProfileOutlined,
    ClusterOutlined,
} from '@ant-design/icons';

const onboardingMenuConfig: MenuConfigType = {
    path: '/onboarding/',
    title: 'Organization Onboarding',
    key: 'OrganizationOnboarding',
    icon: ClusterOutlined,
    guard: [],
    children: [
        {
            path: '/onboarding/license-management',
            title: 'License Management',
            key: 'LicenseManagement',
            icon: ProfileOutlined,
            guard: [],
        },
        {
            path: '/onboarding/organization-management',
            title: 'Organization Management',
            key: 'OrganizationManagement',
            icon: BankOutlined,
            guard: [],
        },
        {
            path: '/onboarding/integration-management',
            title: 'Integration Management',
            key: 'IntegrationManagement',
            icon: DeploymentUnitOutlined,
            guard: [],
        },
        {
            path: '/onboarding/flow-management',
            title: 'Functional Flow Management',
            key: 'FunctionalFlowManagement',
            icon: InteractionOutlined,
            guard: [],
        },
        {
            path: '/onboarding/compliance-management',
            title: 'Compliance Management',
            key: 'ComplianceManagement',
            icon: AuditOutlined,
            guard: [],
        },
        {
            path: '/onboarding/technical-configurations',
            title: 'Technical Configurations',
            key: 'TechnicalConfigurations',
            icon: ToolOutlined,
            guard: [],
        },
        {
            path: '/onboarding/interface-management',
            title: 'Interface Management',
            key: 'InterfaceManagement',
            icon: DesktopOutlined,
            guard: [],
        },
    ],
};

export default onboardingMenuConfig;
