import { UserClaims, CustomUserClaims } from "@okta/okta-auth-js";
import {
  SET_DYNAMIC_ROUTE_KEY,
  USER_LOADED,
} from "../actionTypes/authActionTypes";
export const setRoutRerenderKey = (data: string) => {
  return {
    type: SET_DYNAMIC_ROUTE_KEY,
    payload: data,
  };
};

export const setUserLoaded = (
  userLoaded: boolean,
  user: UserClaims<CustomUserClaims>
) => {
  return {
    type: USER_LOADED,
    userLoaded,
    user,
  };
};
