import React from "react";
import { Layout } from "antd";
import IdleTimer from "react-idle-timer";

import Header from "../../components/Header";
import Breadcrumbs from "../../components/Breadcrumbs";
import PageTitle from "../../components/PageTitle";
import PageContent from "../../components/PageContent";
import config from "../../utils/config";
import SideMenuContainer from "../../components/SideMenu/SideMenuContainer";
import {Footer} from "antd/es/layout/layout";
import {useSelector} from "react-redux";
import {RootState} from "@app/redux/reducers/state";

const MasterLayout = (props: any) => {
  const { tenant } = useSelector(
      (rootState: RootState) => rootState.auth
  );
  const idleTimer = React.createRef() as any;
    const isIframe = window.self !== window.top;
    return (
    <Layout className={"yjMainLayout"} style={{ height: "100vh" }}>
      <React.Fragment>
        <IdleTimer
          key="idleTimer"
          element={document}
          startOnMount={true}
          ref={idleTimer}
          onIdle={(e: Event) => {}}
          onActive={(e: Event) => {}}
          timeout={config.idleTime}
        />
      </React.Fragment>
    {!isIframe && <Header onChangeLanguage={(key: any) => {}} {...props} />}
      <Layout>
          {<SideMenuContainer menuConfig={props.menus} />}
        <Layout className={"yjMasterLayoutWrapper"}>
          <Breadcrumbs routes={props.routes} />
          {props.children}
        </Layout>
      </Layout>
      <Footer style={{ textAlign: 'right', height: '20px', lineHeight: '20px',fontSize: '10px', padding: '0 30px' , backgroundColor: '#24303b', color: '#ffffff'}}>
        {((window as any)._env_?.REACT_APP_VERSION_SHOW === 'true') && (
          <span>Version: {(window as any)._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION} </span>
        )}
        {((window as any)._env_?.REACT_APP_VERSION_SHOW === 'true') && (
          <span>Pipeline Version: {(window as any)._env_?.REACT_APP_PIPELINE_VERSION} </span>
        )}
        <span>Copyright © IRIS Software Group 2023-4 </span>
        <span>Tenant: {tenant}</span>
      </Footer>
    </Layout>
  );
};

export { PageTitle, PageContent };
export default MasterLayout;
