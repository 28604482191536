import React, { ReactNode } from "react";
import { Modal } from "antd";
import Tooltip from "antd/lib/tooltip";
import { ModalProps } from "antd/lib/modal";

type PropTypes = {
  size?: "small" | "medium" | "auto";
  children: ReactNode;
};

export default ({ size, children, ...props }: ModalProps & PropTypes) => {
  let style = "yjCommonModalLarge";
  if (size === "small") {
    style = "yjCommonModalSmall";
  } else if (size === "medium") {
    style = "yjCommonModalMedium";
  } else if (size === "auto") {
    style = "";
  }
  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      className={style}
      {...props}
      title={
        <Tooltip placement="top" title={props.title}>
          {props.title}
        </Tooltip>
      }
    >
      {children}
    </Modal>
  );
};
