import HTTPResponse from "../utils/http/interfaces/HttpResponse";
import http from "../utils/http";
import httpVerbs from "../utils/http/httpVerbs";
import config, { getApiUrl } from "../utils/config";
import { getParameterizedUrl } from "@app/utils";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";

export const fetchUserPermission = async () => {
    const response = await http({
        method: httpVerbs.GET,
        url: getApiUrl(
           config.api[OperationalServiceTypes.UserService].userPermissions
        ),
    });
    // const response = fetch('/data/permissions.json');
    return response;
};

export const getUserPermission = async () => {
    try {
        const response = await fetchUserPermission();
        return await response.data;
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
};

export const getUserNavigationMenu = (): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].userNavigationMenu
    ),
  });
};

export const getUserDetails = (userId: string): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.UserService].userById,
      userId
    ),
  });
};

export const getUserRoles = (): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].getUserRoles
    ),
  });
};

//TODO: getUserGroups currently hardcoded will implemented in create user group
export const getUserGroups = (): Promise<HTTPResponse<any>> => {
  return Promise.resolve({
    status: 200,
    data: [
      { value: 1, name: "Primary Admin Group" },
      { value: 2, name: "Non Primary Admin Group" },
    ],
  });
};

export const getUserStatus = (): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].getUserStatuses
    ),
  });
};

export const getUserCount = (): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.HEAD,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].getUserCount
    ),
  });
};

export const createUser = (userData: any) => {
  return http({
    method: httpVerbs.POST,
    url: getApiUrl(config.api[OperationalServiceTypes.UserService].createUser),
    data: userData,
  });
};

export const updateUser = (
  userId: string,
  data: any
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.PUT,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.UserService].userById,
      userId
    ),
    data: data,
  });
};

export const loadUserDropdownData = (): Promise<any> => {
  return Promise.all([getUserRoles(), getUserStatus()]);
};

export const checkCrossUserExistence = (userEmail: any, contact = false) => {
  return http({
    method: httpVerbs.HEAD,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService]
        .crossOrganizationUserExsistance
    ),
    params: {
      email: userEmail,
      contact: contact,
    },
  });
};

export const checkCrossOrganizationUserExsistance = (
  userEmail: any,
  organizationId: string,
  contact = false
) => {
  return http({
    method: httpVerbs.HEAD,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService]
        .crossOrganizationUserExsistance
    ),
    params: {
      email: userEmail,
      contact: contact,
      organizationId: organizationId,
    },
  });
};

export const requestResetPassword = (userData: any) => {
  return http({
    method: httpVerbs.PUT,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].requestResetPassword
    ),
    data: userData,
  });
};

export const getUserList = (): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getApiUrl(config.api[OperationalServiceTypes.UserService].getUserList),
  });
};
