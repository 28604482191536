import {
  Col,
  Input,
  Row,
  Form,
  Select,
  FormInstance,
  Tag,
  DatePicker,
  Button,
  List,
  Tabs,
  Tooltip,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Rule } from "antd/lib/form";
import React, { useEffect, useRef, useState } from "react";

import { checkCrossUserExistence } from "@app/api/userService";
import { ValueType } from "@app/types/ValueType";
import { AvoidWhitespace, EmailPattern } from "@app/utils/regex";
import styles from "./index.module.less";
import { required, type, typeWithPattern } from "../../validators";
import { formActionType, getParameterizedUrl } from "@app/utils";
import {
  prevenPrecedingSpaces,
  prevenPrecedingSpacesOnPaste,
} from "@app/utils/forms";
import Modal from "@app/components/common/Modal";
import InfinitySelect, { InfinitySelectGetOptions } from "@app/components/InfinitySelect";
import InfinityList from "@app/components/InfinityList";
import config from "@app/utils/config";
import useWindowDimensions from "@app/hooks/useWindowDimensions";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";
import { getInfiniteRecords } from "@app/api/infiniteRecordsService";
import logger from "@app/utils/logger";

const { TabPane } = Tabs;
const INPUT_LENGTH_FIFTY = 50;
const INPUT_TWO_FIVE_FOUR = 254;
const EMAIL_PLACEHOLDER = "user@example.com";
const PRIMARY_ADMIN_ROLE = 1;

const MIN_SCRREN_RESOLUTION = 1366;
const MIN_INFINITY_HEIGHT = 300;
const MAX_INFINITY_HEIGHT = 400;
const NEWLY_SELECTED = "1";
const PREVIOUSLY_SELECTED = "2";

export interface User {
  name?: string;
  action: formActionType;
  formRef?: FormInstance;
  onFinish?: (values: any) => void;
  onChange?: () => void;
  userDetails?: any;
  userData?: any;
}

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const getPopupContainer = (trigger: any) => {
  return trigger.parentNode as HTMLElement;
};

const renderDropdownOptions = (option: ValueType) => {
  return (
    <Option key={option.value} value={option.value}>
      {option.name}
    </Option>
  );
};

export default ({
  action,
  formRef,
  onFinish,
  onChange,
  userDetails,
  userData,
}: User) => {
  const userGroupListModalRef = useRef<HTMLDivElement>(null);
  const [disabledInput, setDisabledInput] = useState<boolean>(false);

  const [selectedUserGroupsList, setSelectedUserGroupsList] = useState<
    Array<any>
  >([]);
  const [userGroups, setUserGroups] = useState<any>();
  const [loadedUserGroups, setLoadedUserGroups] = useState<Array<any>>([]);
  const [
    deletedPreSelectedUserGroups,
    setDeletedPreSelectedUserGroups,
  ] = useState<Array<any>>([]);
  const [removedUserGroupsId, setRemovedUserGroupsId] = useState<
    any | undefined
  >();
  const [selectedTab, setSelectedTab] = useState<any | undefined>();
  const [displayUserGroupViewModal, setDisplayUserGroupViewModal] = useState<
    boolean
  >(false);
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (action === "view") {
      setDisabledInput(true);
    }
    if (userData && action === "edit") {
      setLoadedUserGroups(
        userData.groups.map((userGroup: any) => userGroup.value)
      );
    }
  }, [action, userData]);

  useEffect(() => {
    if (action === "edit") {
      formRef?.setFieldsValue({
        deletedPreSelectedUserGroups: deletedPreSelectedUserGroups,
      });
    } else {
      formRef?.setFieldsValue({
        deletedPreSelectedUserGroups: [],
      });
    }
  }, [deletedPreSelectedUserGroups]);

  const disabledEditInput = (key: string): boolean => {
    if (action === "edit") {
      if (key === "role" || key === "groups") {
        const { role } = formRef?.getFieldsValue();
        if (role === PRIMARY_ADMIN_ROLE) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const existOnOtherOrganization = (emailInput: any) => {
    return new Promise((resolve, reject) => {
      if (EmailPattern.test(emailInput)) {
        if (action === "edit") {
          resolve(true);
        } else {
          checkCrossUserExistence(emailInput)
            .then(() => {
              reject("The user already exists");
            })
            .catch(() => {
              checkCrossUserExistence(emailInput, true)
                .then(() => {
                  reject("The contact already exists");
                })
                .catch(() => {
                  resolve(true);
                });
            });
        }
      } else {
        resolve(true);
      }
    });
  };

  const userExistsInOtherOrganizations = (): Rule => ({
    validator(rule, value) {
      return value ? existOnOtherOrganization(value) : Promise.resolve();
    },
  });

  const renderUserGroupListBtn = () => {
    const selectedCount =
      action === "edit"
        ? selectedUserGroupsList.length +
          (loadedUserGroups.length - deletedPreSelectedUserGroups.length)
        : selectedUserGroupsList?.length;

    const defaultLabel = disabledEditInput("groups")
      ? "Not Applicable"
      : "None Selected";

    return (
      <Button
        className="yjNoWrapperButton"
        disabled={disabledEditInput("groups")}
        onClick={() => setDisplayUserGroupViewModal(true)}
      >
        {selectedCount === 0 ? defaultLabel : `${selectedCount} Selected`}
      </Button>
    );
  };

  const onRemoveUserGroup = (userGroupId: any) => {
    onChange && onChange();
    const currenntUserGroups = selectedUserGroupsList.filter(
      (userGroup) => userGroup.id !== userGroupId
    );
    setSelectedUserGroupsList(currenntUserGroups);
    setRemovedUserGroupsId(userGroupId);
    formRef?.setFieldsValue({
      groups: currenntUserGroups?.map((userGroup: any) => {
        return userGroup.id;
      }),
    });
  };

  const onRemovePreSelectedUserGroup = (userGroupId: any) => {
    onChange && onChange();
    setRemovedUserGroupsId(userGroupId);
    setDeletedPreSelectedUserGroups((deletedUserGroups) => [
      ...deletedUserGroups,
      userGroupId,
    ]);
  };

  const renderUserGroupList = () => (
    <List
      locale={{ emptyText: "No User Groups Selected" }}
      itemLayout="horizontal"
      dataSource={selectedUserGroupsList}
      renderItem={(item: any) => (
        <List.Item key={item.id}>
          <div className={styles.yjManageUsersNewlySelectedUsersList}>
            <div className={styles.yjManageUsersNewlySelectedUsersListLeft}>
              <Tooltip title={item.name} placement={"topLeft"}>
                <p className={styles.yjManageUsersNewlySelectedUsersListName}>
                  {item.name}
                </p>
              </Tooltip>
            </div>
            <div className={styles.yjManageUsersNewlySelectedUsersListRight}>
              <Button
                onClick={() => onRemoveUserGroup(item.id)}
                icon={<CloseOutlined />}
              />
            </div>
          </div>
        </List.Item>
      )}
    />
  );

  const renderInfinityListItem = (value: any, onRemove: any) => (
    <div className={styles.yjManageUsersInfinityListComponent}>
      <div className={styles.yjManageUsersListItemValue}>
        <Tooltip title={value.name} placement={"topLeft"}>
          <p>{value.name}</p>
        </Tooltip>
      </div>
      <div className={styles.yjManageUsersListItemAction}>
        <Button
          //Value and keyValue
          onClick={() => onRemove(value.id, "id")}
          icon={<CloseOutlined />}
        />
      </div>
    </div>
  );

  const renderTabs = () => (
    <Tabs onChange={setSelectedTab} activeKey={selectedTab}>
      <TabPane
        tab={
          <>
            Newly Selected{" "}
            <span className={styles.yjBadge}>
              {selectedUserGroupsList.length}
            </span>
          </>
        }
        key={NEWLY_SELECTED}
      >
        {renderUserGroupList()}
      </TabPane>
      <TabPane
        tab={
          <>
            Previously Selected
            <span className={styles.yjBadge}>
              {loadedUserGroups.length - deletedPreSelectedUserGroups.length}
            </span>
          </>
        }
        key={PREVIOUSLY_SELECTED}
      >
        <InfinityList
          heightInPx={
            width <= MIN_SCRREN_RESOLUTION
              ? MIN_INFINITY_HEIGHT
              : MAX_INFINITY_HEIGHT
          }
          hasCheckbox={false}
          paginatedLimit={10}
          endpoint={getParameterizedUrl(
            config.api[OperationalServiceTypes.UserService].userGroupByuser,
            userData.userId
          )}
          listClassName={"yjInfinityListClass"}
          notFoundContent="No User Groups Selected"
          idKeyValue="id"
          onItemRemove={(userGroup) => onRemovePreSelectedUserGroup(userGroup)}
          removedItem={deletedPreSelectedUserGroups}
          formatValue={(value: any, onRemove) => {
            return renderInfinityListItem(value, onRemove);
          }}
        />
      </TabPane>
    </Tabs>
  );

  const onChangeUserGroup = (
    event: any,
    userGroupList: Array<any> | undefined
  ) => {
    setUserGroups(event);
    if (userGroupList) {
      if (action === "edit") {
        const nonPreSelectedUserGroupList = userGroupList
          ?.filter((userGroup: any) => !loadedUserGroups.includes(userGroup.id))
          .map((userGroup) => userGroup.id);
        formRef?.setFieldsValue({
          groups: nonPreSelectedUserGroupList,
        });
        const remainigPreSelectedUserGroup = event.filter(
          (val: any) => !nonPreSelectedUserGroupList.includes(val)
        );
        const removedPreSelectedUserGroup = loadedUserGroups.filter(
          (val: any) => !remainigPreSelectedUserGroup.includes(val)
        );
        setDeletedPreSelectedUserGroups(removedPreSelectedUserGroup);
        setSelectedUserGroupsList(
          userGroupList.filter(
            (userGroup) => !loadedUserGroups.includes(userGroup.id)
          )
        );
      } else {
        formRef?.setFieldsValue({
          groups: userGroupList?.map((userGroup: any) => {
            return userGroup.id;
          }),
        });
        setSelectedUserGroupsList(userGroupList);
      }
    }
  };

  const userGroupDisplayModal = () => {
    return (
      <Modal
        size={"small"}
        destroyOnClose={false}
        visible={displayUserGroupViewModal}
        key="userGroupDisplayModal"
        title="Selected User Groups"
        closable={true}
        onCancel={() => {
          setSelectedTab(NEWLY_SELECTED);
          setDisplayUserGroupViewModal(false);
        }}
        footer={[
          <Button
            key="yes"
            onClick={() => {
              setSelectedTab(NEWLY_SELECTED);
              setDisplayUserGroupViewModal(false);
            }}
            type="default"
          >
            CLOSE
          </Button>,
        ]}
      >
        <div
          ref={userGroupListModalRef}
          className={styles.yjModalContentWrapper}
        >
          <div>{action === "edit" ? renderTabs() : renderUserGroupList()}</div>
        </div>
      </Modal>
    );
  };

  const getPaginatedRecords = async  (page: number, method: InfinitySelectGetOptions, searchValue?: string): Promise<Array<any>> => {
    const transformFilters: any = {};
    /**
     * Will add the keyvalue if dropdown still visible
     */
    if (searchValue) {
      transformFilters.search = searchValue;
    }

    const options = {
      limit: 10,
      offset: page - 1,
      ...transformFilters
    }
    return getInfiniteRecords(config.api[OperationalServiceTypes.MasterDataService].channels, options)
        .then((res: any) => {
          logger.info('SideSelection','getPaginatedRecords',res.data);
          if (res.data) {
            return res.data;
          } else {
            logger.error('SideSelection','getPaginatedRecords',res.error);
            return []
          }
        })
        .catch((error: any) => {
          logger.error('SideSelection','getPaginatedRecords',error);

          return [];
        });
  };

  const renderUserGroup = () => (
    <Row gutter={24}>
      <Col span={18}>
        <div className="yjInfinateScrollMultiselector">
          <Form.Item label="Assign User Group(s)" name="groups">
            <InfinitySelect
              mode="multiple"
              getPaginatedRecords={getPaginatedRecords}
              formatValue={(value) => {
                return `${value.name}`;
              }}
              notFoundContent="No User Groups Available"
              notLoadContent="Failed to load values in user group dropdown"
              value={userGroups}
              onChange={onChangeUserGroup}
              defaultValues={loadedUserGroups}
              removeById={removedUserGroupsId}
              disabled={disabledInput || disabledEditInput("groups")}
              placeholder={
                disabledEditInput("groups")
                  ? "Not Applicable"
                  : "Select User Groups"
              }
              maxTagCount={0}
              maxTagPlaceHolder={() => `Select User Groups`}
              returnObject={true}
            />
          </Form.Item>
        </div>
      </Col>
      <Col span={4}>{renderUserGroupListBtn()}</Col>
    </Row>
  );

  const commonUserDetails = () => {
    return (
      <>
        <Col span={8}>
          <Form.Item
            label={"First Name"}
            name="firstName"
            rules={[required, typeWithPattern("string", AvoidWhitespace)]}
          >
            <Input
              onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
              onKeyDown={(event) => prevenPrecedingSpaces(event)}
              maxLength={INPUT_LENGTH_FIFTY}
              disabled={disabledInput}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Last Name"}
            name="lastName"
            rules={[required, typeWithPattern("string", AvoidWhitespace)]}
          >
            <Input
              onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
              onKeyDown={(event) => prevenPrecedingSpaces(event)}
              maxLength={INPUT_LENGTH_FIFTY}
              disabled={disabledInput}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Email Address"}
            name="email"
            rules={[
              required,
              typeWithPattern("string", AvoidWhitespace),
              type("email", `Invalid Input. E.g. ${EMAIL_PLACEHOLDER}`),
              userExistsInOtherOrganizations(),
            ]}
          >
            <Input
              maxLength={INPUT_TWO_FIVE_FOUR}
              onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
              onKeyDown={(event) => prevenPrecedingSpaces(event)}
              placeholder={EMAIL_PLACEHOLDER}
              disabled={disabledInput || disabledEditInput("email")}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            initialValue={3}
            label="User role"
            name="role"
            rules={[required]}
          >
            <Select
              notFoundContent={`No User Roles Available`}
              disabled={disabledInput || disabledEditInput("role")}
              style={{ width: "100%" }}
              getPopupContainer={getPopupContainer}
            >
              {userDetails && userDetails.roles
                ? (userDetails.roles as ValueType[]).map((option) =>
                    renderDropdownOptions(option)
                  )
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={[required]}
            initialValue={1}
            label="Status"
            name="status"
          >
            <Select
              notFoundContent={`No User Status Available`}
              disabled={true}
              style={{ width: "100%" }}
              getPopupContainer={getPopupContainer}
            >
              {userDetails && userDetails.status
                ? (userDetails.status as ValueType[]).map((option) =>
                    renderDropdownOptions(option)
                  )
                : null}
            </Select>
          </Form.Item>
        </Col>
      </>
    );
  };

  const generateUpdatedMode = () => {
    return (
      <>
        <Col span={8}>
          <Form.Item label="User Id" name="userId">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        {commonUserDetails()}
        <Col span={8}>
          <Form.Item label="Created By" name="createdBy">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Created Date" name="created">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Modified Date" name="modified">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={8}>{renderUserGroup()}</Col>
        <Form.Item label="" name="deletedPreSelectedUserGroups" />
        <Form.Item label="" name="loadedPreSelectedUserGroups" />
      </>
    );
  };

  const generateEditMode = () => {
    return (
      <>
        {commonUserDetails()}
        <Col span={8}>{renderUserGroup()}</Col>
      </>
    );
  };

  const generateViewMode = () => {
    return (
      <>
        <Col span={8}>
          <Form.Item label={"User ID"} name="userId" rules={[required]}>
            <Input
              onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
              onKeyDown={(event) => prevenPrecedingSpaces(event)}
              disabled={disabledInput}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        {commonUserDetails()}
        <Col span={8}>
          <Form.Item label={"User Groups"} name="groups">
            <Tag color={"blue"}>UserGroup1</Tag>
            <Tag color={"blue"}>UserGroup2</Tag>
            <Tag color={"blue"}>UserGroup2</Tag>
            <Tag color={"blue"}>UserGroup2</Tag>
            <Tag color={"blue"}>UserGroup2</Tag>
            <Tag color={"blue"}>UserGroup2</Tag>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"Created Date"} name="created">
            <DatePicker disabled={disabledInput} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"Modified Date"} name="modified">
            <DatePicker disabled={disabledInput} autoComplete="off" />
          </Form.Item>
        </Col>
      </>
    );
  };

  return (
    <>
      {userGroupDisplayModal()}
      <div className={styles.yjAddUserManagementWrapper}>
        <h2 className={styles.yjModuleSubHeading}>Basic Information</h2>
        <Form
          form={formRef}
          key="addUserForm"
          onFinish={onFinish}
          {...layout}
          layout="horizontal"
          onChange={onChange}
        >
          <Row gutter={16}>
            {action === "view" && generateViewMode()}
            {action === "edit" && generateUpdatedMode()}
            {action === "save" && generateEditMode()}
          </Row>
        </Form>
      </div>
    </>
  );
};
