import { REMOVE_USER_DATA, SET_USER_DATA, SET_USER_PERMISSION, } from "../actionTypes/userManagementActionTypes";

export const setUserData = (data: any) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

export const setUserPermission = (data: any) => {
  return {
    type: SET_USER_PERMISSION,
    payload: data,
  };
};

export const removeUserData = () => {
  return {
    type: REMOVE_USER_DATA,
  };
};
