import { REMOVE_USER_DATA, SET_USER_DATA, SET_USER_PERMISSION } from "../actionTypes/userManagementActionTypes";

import { IUserManagement } from "../../redux/types/userManagement";
import { UserPermission } from "@app/types/UserPermission";

export const defaultUserPermission: UserPermission = {
  privDMSCanViewFileArea: false,
  privDMSCanUploadFiles: false,
  privDMSCanManageFileTags: false,
  privDMSCanDeleteFileTags: false,
  privDMSCanManageFileAssign: false,
  privDMSCanRecategorizeFiles: false,
  privDMSCanManageFileStatus: false,
  privDMSCanCheckInCheckOutInternalFiles: false,
  privDMSCanPublishUnpublishInternalFiles: false,
  privDMSCanViewFileHistory: false,
  privDMSCanDeleteFiles: false,
  privDMSCanManageFileProperties: false,
  privDMSCanMarkFilesAsToBeDeleted: false,
  privDMSCanViewFileSourceLink: false,
  privDMSCanCreateFileArea: false,       // New attribute
  privDMSCanViewTemplates: false,
  privDMSCanCreateTemplates: false,
  privDMSCanManageTemplates: false,
  privDMSCanManageAutoFiling: false,     // New attribute
  privDMSCanManageRetention: false,      // New attribute
  privDMSCanMoveFiles: false,            // New attribute
  privDMSCanRenameFiles: false,          // New attribute
  privDMSCanCopyFiles: false             // New attribute
};

export const initialState: IUserManagement = {
  userDetails: undefined,
  userPermission: defaultUserPermission,
};

export const userManagementReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_USER_PERMISSION:
      return {
        ...state,
        userPermission: action.payload,
      };
    case REMOVE_USER_DATA:
      return {
        ...state,
        userDetails: undefined,
      };
    default:
      return state;
  }
};
