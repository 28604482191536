import http from "@app/utils/http";
import HTTPResponse from "@app/utils/http/interfaces/HttpResponse";
import httpVerbs from "@app/utils/http/httpVerbs";

export const getInfiniteRecords = (
  endpoint: string,
  options: any
): Promise<HTTPResponse<any>> => {
  const params = options;

  if (options.search) {
    params.search = options.search;
  }

  if (options.channelId) {
    params.channelId = options.channelId;
  }

  if (options.queryParameters) {
    for (const key in options.queryParameters) {
      if (options.queryParameters.hasOwnProperty(key)) {
        options[key] = options.queryParameters[key];
      }
    }
  }

  const requestObject = {
    method: httpVerbs.GET,
    url: `${endpoint}`,
    params: params,
    headers: {
      "content-type": "application/json",
    },
    data: options.filters,
  };

  return http(requestObject);
};
