import { FileDetailsState } from "../types/FileDetailsState";
import {
  FileDetailsActionTypes,
  SET_FILE_STATUSES,
  SET_FILE_YEARS,
  SET_PROJECTS,
  SET_USERS,
  SET_IS_OPTIONS_FETCHED,
  SET_SUCCESSED_FILES,
  SET_PENDING_SAVE,
  SET_FILE_TYPES,
} from "../actionTypes/fileDetailsActionTypes";

export const initialState: FileDetailsState = {
  options: {
    fileStatuses: [],
    years: [],
    projects: [],
    users: [],
    folderTree: { siteId: "", siteName: "", siteStatusId: -1, folders: [] },
    fileTypes: [],
  },
  isOptionsFetched: false,
  successedFiles: [],
  pendingSave: false,
};

export default (
  state = initialState,
  action: FileDetailsActionTypes
): FileDetailsState => {
  switch (action.type) {
    case SET_FILE_STATUSES:
      return {
        ...state,
        options: {
          ...state.options,
          fileStatuses: action.fileStatuses,
        },
      };
    case SET_FILE_YEARS:
      return {
        ...state,
        options: {
          ...state.options,
          years: action.years,
        },
      };
    case SET_PROJECTS:
      return {
        ...state,
        options: {
          ...state.options,
          projects: action.projects,
        },
      };

    case SET_USERS:
      return {
        ...state,
        options: {
          ...state.options,
          users: action.users,
        },
      };
    case SET_FILE_TYPES:
      return {
        ...state,
        options: {
          ...state.options,
          fileTypes: action.fileTypes,
        },
      };
    case SET_IS_OPTIONS_FETCHED:
      return {
        ...state,
        isOptionsFetched: action.isOptionsFetched,
      };
    case SET_SUCCESSED_FILES:
      return {
        ...state,
        successedFiles: action.successedFiles,
      };
    case SET_PENDING_SAVE:
      return {
        ...state,
        pendingSave: action.pendingSave,
      };
    default:
      return state;
  }
};
