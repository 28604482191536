import React, { useState, useEffect, useRef } from "react";
import { List, Spin, Checkbox, Input, Menu, Dropdown } from "antd";
import debounce from "lodash/debounce";

import styles from "./index.module.less";
import { getInfiniteRecords } from "@app/api/infiniteRecordsService";
import config from "@app/utils/config";
import { errorNotification } from "@app/utils/antNotifications";
import logger from "@app/utils/logger";
import { DownOutlined } from "@ant-design/icons";
import { uniqueArray } from "../../utils/array";

const CheckboxGroup = Checkbox.Group;
const ListItem = List.Item;
const Search = Input.Search;

const PAGINATED_TEN = 10;
const PAGINATED_TWENTY = 20;
const PAGINATED_THIRTY = 30;

type paginatedLimit =
  | typeof PAGINATED_TEN
  | typeof PAGINATED_TWENTY
  | typeof PAGINATED_THIRTY;

export interface IList {
  endpoint: string;
  idKeyValue: string;
  hasCheckbox?: boolean;
  hasSearch?: boolean;
  heightInPx?: number;
  paginatedLimit?: paginatedLimit;
  formatValue: (value: any, onRemove?: any) => any;
  onSelect?: (
    value: any,
    selectedList: any[],
    totalRecordCountValue: any,
    clearVisible?: boolean
  ) => void;
  notFoundContent: string;
  selectedList?: any[];
  listClassName?: string;
  onChange?: (changed: boolean) => void;
  onChangeCheckBox?: (event: any, itemId: any) => void;
  showRecordCounter?: boolean;
  selectedCount?: number;
  checkedList?: any[];
  deletedList?: any[];
  onTotalCount?: (value: number) => void;
  onItemRemove?: (value: string) => any;
  removedItem?: any[];
  listItemStyle?: any;
  appendedValues?: any[];
  mapData?: (data: any) => any;
}

type getOptions = "fetch" | "load";

const DEFAULT_VIEW_SIZE = 50;
const DEFAULT_DIV_HEIGHT = 400;
const DEFAULT_LOAD_VALUE = 5;
const DROPDOWN_SELECT_VALUE_NONE = "Clear All";
const DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE = "Clear Visible";
const DROPDOWN_SELECT_VALUE_SELECT_ALL_VISIBLE = "Select Visible";
const DROPDOWN_SELECT_VALUE_SELECT_ALL = "Select All";
const LIMIT = 10;

export default (props: IList) => {
  const infinityListRef = useRef({}) as any;
  /**
   * Loader For
   * Load More For Pagination => loading
   * Init API calls for search and normal
   */
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [isEndOfScroll, setIsEndOfScroll] = useState<boolean>(false);

  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState<boolean | undefined>();

  const [items, setItems] = useState<Array<any>>([]);
  const [keyValue, setKeyValue] = useState<string>("");

  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | undefined
  >(DROPDOWN_SELECT_VALUE_NONE);

  const [historyOptions, setHistoryOptions] = useState<Array<any>>([]);

  /**
   * To Keep track of previously selected Value
   */
  const [
    previouslySelectedDropdownValue,
    setPreviouslySelectedDropdownValue,
  ] = useState<string | undefined>(DROPDOWN_SELECT_VALUE_NONE);
  const [previouslyCheckedList, setPreviouslyCheckedList] = useState<
    any[] | undefined
  >();

  /**
   * To Keep track of Counts for validation
   */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
  const [appendItems, setAppendItems] = useState<Array<any>>([]);

  useEffect(() => {
    if (props.appendedValues) {
      let remainingItems = items.filter(
        (item) =>
          appendItems.filter(
            (item2: any) => item2[props.idKeyValue] === item[props.idKeyValue]
          ).length == 0
      );
      setAppendItems(props.appendedValues);
      const appendedValues = [...props.appendedValues].reverse();
      setItems(appendedValues.concat(remainingItems));
    }
  }, [props.appendedValues, props.removedItem]);

  useEffect(() => {
    setFetching(true);
    setCheckAll(false);
    props.selectedList && setCheckedList(props.selectedList);
    getPaginatedRecords(1, "fetch", keyValue).then((res: any) => {
      if (res.length > 0) {
        setItems(res);
        if (props.selectedList) {
          const visibleElemnts = props.selectedList.filter((x) =>
            res.some((y: any) => y.id === x)
          );
          setIndeterminate(
            !!visibleElemnts.length && visibleElemnts.length < res.length
          );
          setCheckAll(visibleElemnts.length === res.length);
        }
      } else {
        setItems([]);
      }
      setFetching(false);
    });

    const checkedListValues = props.checkedList
      ? props.checkedList.map((value) => {
          return {
            id: value.id,
            name: value.name,
            displayText: value.displayText,
          };
        })
      : [];

    setHistoryOptions((tmpHistoryOptions: any) => {
      return uniqueArray([...tmpHistoryOptions, ...checkedListValues]);
    });
  }, [props.endpoint]);

  /**
   * LoadMore wil trigger if items's size less than 5
   * Working
   */
  useEffect(() => {
    const deletedList = props.deletedList ? props.deletedList : [];
    const selectedList = props.selectedList
      ? props.selectedList.filter((x) => !deletedList.includes(x))
      : [];

    const selectedItems = historyOptions.filter(
      (item) =>
        [...selectedList, ...checkedList] &&
        [...selectedList, ...checkedList].includes(item.id)
    );

    props.onSelect &&
      props.onSelect(
        checkedList,
        selectedItems,
        totalRecordCount,
        selectedDropdownValue === DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE
      );

    setPreviouslyCheckedList((previousList) => {
      return previousList?.map((value) => {
        if (!checkedList?.includes(value)) {
          return value;
        }
      });
    });
  }, [checkedList, props.deletedList]);

  useEffect(() => {
    if (
      props.removedItem &&
      props.removedItem.length >= items.length - DEFAULT_LOAD_VALUE &&
      items.length > 0 &&
      props.removedItem.length > 0 &&
      pageCount >= currentPage &&
      !loading
    ) {
      loadMore();
    }
  }, [props.removedItem, items, loading]);

  useEffect(() => {
    props.onTotalCount && props.onTotalCount(totalRecordCount);
  }, [totalRecordCount]);

  const onChange = (list: any) => {
    props.onChange && props.onChange(true);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < items.length);
    setCheckAll(list.length === items.length);
  };

  const setHeaderCheckboxOptions = (
    intermediate: boolean,
    selectAll: boolean,
    checkedListValues: any
  ) => {
    setIndeterminate(intermediate);
    setCheckAll(selectAll);
    setCheckedList(checkedListValues);
  };

  const getCommonSites = () => {
    const checkedListValue = props.checkedList ? props.checkedList : [];
    const commonSites: any[] = [];
    checkedListValue.forEach((viewListItem: any) => {
      if (!items.some((list: any) => list.id === viewListItem.id)) {
        commonSites.push(viewListItem);
      } else {
        props.onChangeCheckBox &&
          props.onChangeCheckBox({ checked: false }, viewListItem.id);
      }
    });
    return commonSites;
  };

  const handleCheckedChannels = () => {
    const commonSites = getCommonSites();
    if (props.checkedList && props.checkedList.length > 0) {
      const checkedListLength = props.checkedList
        ? props.checkedList.length
        : 0;

      const tmpCheckList = props.checkedList ? props.checkedList : [];
      const visibleElemnts = items.filter((x) =>
        tmpCheckList.some((y: any) => y.id === x.id)
      );

      if (!!visibleElemnts.length && checkedListLength === items.length) {
        setHeaderCheckboxOptions(false, false, []);
      } else {
        if (props.checkedList.length > items.length) {
          setHeaderCheckboxOptions(false, false, [
            ...commonSites.map((val) => val.id),
          ]);
        } else {
          const mergedSites = items.map((item: any) => item.id);
          const selectedValues = props.selectedList ? props.selectedList : [];
          const mergedValues = [...mergedSites, ...selectedValues];
          setHeaderCheckboxOptions(false, true, mergedValues);
        }
      }
    } else {
      setHeaderCheckboxOptions(
        false,
        true,
        items.map((item: any) => item.id)
      );
    }
  };

  const onCheckAllChange = (e: any) => {
    props.onChange && props.onChange(true);
    if (selectedDropdownValue !== DROPDOWN_SELECT_VALUE_SELECT_ALL) {
      handleCheckedChannels();
      setSelectedDropdownValue(
        e.target.checked
          ? DROPDOWN_SELECT_VALUE_SELECT_ALL_VISIBLE
          : DROPDOWN_SELECT_VALUE_NONE
      );
    } else {
      setCheckedList(e.target.checked ? items.map((item: any) => item.id) : []);
      setSelectedDropdownValue(DROPDOWN_SELECT_VALUE_NONE);
      setIndeterminate(e.target.checked ? true : false);
      setCheckAll(e.target.checked);
    }
  };
  /**
   * Ant Design Won't allow to access the debounceFetcher
   * x => React.useMemo with return
   */
  const onSearch = (value: any) => {
    debounceFetcher(value.target.value);
  };
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (keyVal: string) => {
      setItems([]);
      setCheckAll(false);
      setIndeterminate(false);
      setCheckedList([]);
      setFetching(true);
      setSelectedDropdownValue(DROPDOWN_SELECT_VALUE_NONE);
      setIsEndOfScroll(false);
      getPaginatedRecords(1, "fetch", keyVal).then((res: Array<any>) => {
        if (res.length > 0) {
          setKeyValue(keyVal);
          setItems(res);
        } else {
          setItems([]);
        }
        setFetching(false);
      });
    };
    return debounce(loadOptions, config.inputDebounceInterval);
  }, [config.inputDebounceInterval]);

  const loadMore = () => {
    setIsEndOfScroll(false);
    setLoading(true);
    getPaginatedRecords(currentPage + 1, "load", keyValue).then((res: any) => {
      if (res.length > 0) {
        setItems(res);
        if (checkAll) {
          setIndeterminate(true);
          setCheckAll(false);
        } else {
          const selectedListCount = props.selectedList
            ? props.selectedList.length
            : 0;

          if (res.length && selectedListCount > 0) {
            setIndeterminate(true);
            setCheckAll(false);
          }
        }
        if (
          props.hasCheckbox &&
          selectedDropdownValue === DROPDOWN_SELECT_VALUE_SELECT_ALL
        ) {
          setCheckedList(res.map((item: any) => item.id));
        } else if (
          props.hasCheckbox &&
          selectedDropdownValue === DROPDOWN_SELECT_VALUE_SELECT_ALL_VISIBLE
        ) {
          setIndeterminate(true);
        } else if (
          props.hasCheckbox &&
          selectedDropdownValue === DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE
        ) {
          if (
            previouslySelectedDropdownValue === DROPDOWN_SELECT_VALUE_SELECT_ALL
          ) {
            setCheckedList(
              res.map((item: any) => {
                if (!previouslyCheckedList?.includes(item.id)) {
                  return item.id;
                }
              })
            );
            setIndeterminate(true);
          } else {
            setIndeterminate(false);
          }
        } else {
          if (props.selectedList && props.selectedList.length > 0) {
            const visibleElemnts = props.selectedList.filter((x) =>
              res.some((y: any) => y.id === x)
            );

            setIndeterminate(
              !!visibleElemnts.length && visibleElemnts.length < res.length
            );
            setCheckAll(visibleElemnts.length === res.length);
          }
          setCheckedList(
            props.checkedList ? props.checkedList.map((x: any) => x.id) : []
          );
        }
      } else {
        setItems([]);
      }
      setLoading(false);
    });
  };
  /**
   * Common API call method
   * searchValue => for search
   * selectedCount => Next Page Count for loadmore
   * method: "fetch" => init,  will create in new options  | "load" => append with old options,
   */

  const getPaginatedRecords = async (
    selectedCount: number,
    method: getOptions,
    searchValue?: string
  ): Promise<Array<any>> => {
    const transformFilters: any = {};
    if (searchValue) {
      transformFilters.search = searchValue;
    }
    return getInfiniteRecords(props.endpoint, {
      pagination: {
        current: selectedCount,
        pageSize: props?.paginatedLimit || LIMIT,
      },
      ...transformFilters,
    })
      .then((res: any) => {
        setCurrentPage(res.data.pageNumber);
        setPageCount(res.data.pageCount);
        setTotalRecordCount(res.data.totalRecordCount);
        if (props.mapData) {
          res.data.records = res.data.records.map(props.mapData);
        }
        setHistoryOptions((tmpHistoryOptions: any) => {
          return uniqueArray([...tmpHistoryOptions, ...res.data.records]);
        });
        return method === "fetch"
          ? uniqueArray([...res.data.records])
          : uniqueArray([...items, ...res.data.records]);
      })
      .catch((error: any) => {
        logger.error("Genraric Module", "Inifinity Component", error);
        errorNotification([""], "something went wrong please try again later");
        return [];
      });
  };

  const onScroll = () => {
    let lastElement: any = document.getElementById(
      items ? `item-${(items || []).length - 1}` : "null"
    );

    if (props.idKeyValue) {
      const getIds = items.map(
        (x) => x[props.idKeyValue ? props.idKeyValue : ""]
      );
      const remainigPreSelectedContact =
        props.removedItem && props.removedItem.length > 0
          ? getIds.filter((val: any) =>
              props.removedItem ? !props.removedItem.includes(val) : val
            )
          : getIds;

      lastElement = document.getElementById(
        items
          ? `item-${
              remainigPreSelectedContact[remainigPreSelectedContact.length - 1]
            }`
          : "null"
      );
    }

    const containerTop = infinityListRef
      ? infinityListRef?.current.getBoundingClientRect().top
      : null;

    const lastElementTopPos =
      lastElement?.getBoundingClientRect().top - containerTop;
    const containerHeight = infinityListRef?.current?.getBoundingClientRect()
      .height;

    if (
      lastElementTopPos - DEFAULT_VIEW_SIZE < containerHeight &&
      pageCount > currentPage &&
      !loading
    ) {
      loadMore();
    } else {
      if (pageCount === currentPage) {
        setIsEndOfScroll(true);
      }
    }
  };

  const handleMenuClick = (e: any) => {
    setSelectedDropdownValue((oldSelectedValue) => {
      setPreviouslySelectedDropdownValue(oldSelectedValue);
      return e.key;
    });
    props.onChange && props.onChange(true);
    if (
      e.key === DROPDOWN_SELECT_VALUE_NONE ||
      e.key === DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE
    ) {
      setCheckAll(false);
      setIndeterminate(false);

      const commonSites = getCommonSites();

      setCheckedList((oldCheckedList) => {
        if (e.key === DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE) {
          setPreviouslyCheckedList(oldCheckedList);
        }
        return [...commonSites.map((val) => val.id)];
      });
    } else {
      setIndeterminate(
        e.key === DROPDOWN_SELECT_VALUE_SELECT_ALL_VISIBLE ? false : true
      );
      setCheckAll(true);
      setCheckedList((oldCheckedList) =>
        uniqueArray([...items.map((item: any) => item.id), ...oldCheckedList])
      );
    }
  };

  const generateRecordCountLabel = () => {
    const recordCount = props.selectedCount ? props.selectedCount : 0;
    const singleRecord = " - 1 Record Selected";
    const multipleReords = ` - ${recordCount} Records Selected`;
    const displayLabel = recordCount === 1 ? singleRecord : multipleReords;
    const loadedRecords =
      (props.paginatedLimit ? props.paginatedLimit : LIMIT) * currentPage;
    const displayLoadedRecords =
      loadedRecords <= totalRecordCount ? loadedRecords : totalRecordCount;
    return (
      <p className={styles.yjSwitcherSelectedRecords}>
        {`${displayLoadedRecords} of ${totalRecordCount} Result(s)  ${
          recordCount > 0 ? displayLabel : ""
        }  `}
      </p>
    );
  };

  const onRemove = (id: any) => {
    props.onItemRemove && props.onItemRemove(id);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={DROPDOWN_SELECT_VALUE_SELECT_ALL_VISIBLE}>
        {DROPDOWN_SELECT_VALUE_SELECT_ALL_VISIBLE}
      </Menu.Item>
      <Menu.Item hidden={true} key={DROPDOWN_SELECT_VALUE_SELECT_ALL}>
        {DROPDOWN_SELECT_VALUE_SELECT_ALL}
      </Menu.Item>
      <Menu.Item key={DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE}>
        {DROPDOWN_SELECT_VALUE_CLEAR_VISIBLE}
      </Menu.Item>
      <Menu.Item hidden={true} key={DROPDOWN_SELECT_VALUE_NONE}>
        {DROPDOWN_SELECT_VALUE_NONE}
      </Menu.Item>
    </Menu>
  );
  const renderList = () => (
    <div
      style={{
        height: props?.heightInPx || DEFAULT_DIV_HEIGHT,
        overflow: "auto",
      }}
      onScroll={onScroll}
      ref={infinityListRef}
    >
      <List
        className={props.listClassName}
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item, index) => {
          if (
            props?.idKeyValue &&
            !props.removedItem?.includes(item[props?.idKeyValue])
          ) {
            return (
              <ListItem
                key={index}
                id={`item-${
                  props?.idKeyValue ? item[props?.idKeyValue] : index
                }`}
              >
                <div className={props.listItemStyle ? props.listItemStyle : ""}>
                  <div>
                    {props.hasCheckbox && (
                      <Checkbox
                        onChange={(event) =>
                          props.onChangeCheckBox &&
                          props.onChangeCheckBox(event.target, item.id)
                        }
                        value={item.id}
                      ></Checkbox>
                    )}
                  </div>
                  <div>
                    {props.formatValue(item, (value: any) => onRemove(value))}
                  </div>
                </div>
              </ListItem>
            );
          }
        }}
      >
        <div className={styles.yjInfinityLoadMore}>
          <Spin spinning={loading || fetching} />
          {isEndOfScroll && (
            <div className={styles.yjInfinityScrollText}>
              <p>You have seen it all !</p>
            </div>
          )}
          {(items.length === 0 ||
            (props.removedItem && props.removedItem.length === items.length)) &&
            !loading &&
            !fetching &&
            props.notFoundContent}
        </div>
      </List>
    </div>
  );

  return (
    <>
      {props.hasSearch && (
        <Search
          placeholder="input search loading default"
          onChange={onSearch}
        />
      )}
      {props.hasCheckbox ? (
        <>
          <div className={styles.yjSelectOptionSwitcher}>
            <Dropdown overlay={menu}>
              <a href="#drop-down" onClick={(e) => e.preventDefault()}>
                <DownOutlined />
              </a>
            </Dropdown>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            ></Checkbox>
            {props.showRecordCounter &&
              totalRecordCount > 0 &&
              generateRecordCountLabel()}
          </div>
          <div className={`${styles.yjInfiniteListItems}`}>
            <CheckboxGroup
              style={{ width: "100%" }}
              onChange={onChange}
              value={checkedList}
            >
              {renderList()}
            </CheckboxGroup>
          </div>
        </>
      ) : (
        renderList()
      )}
    </>
  );
};
