import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import AppConfig from 'app/types/AppConfig';

const w = window as any;

const config: AppConfig = {
  logLevel: process.env.REACT_APP_LOG_LEVEL || (w._env_ && w._env_.REACT_APP_LOG_LEVEL) || 'debug',
  discoveryURL: process.env.REACT_APP_DISCOVERY_URL || (w._env_ && w._env_.REACT_APP_DISCOVERY_URL) || 'https://discovery.dev.conarc.net',
  api: {
    [OperationalServiceTypes.PortalService]: {
      getSubmittedFilesColumns: `api/files/submitted/columns`,
      getSubmittedFiles: `api/files/submitted`,
      portalFilesUpload: `api/files`,
      downloadPortalZipFile: `api/files/submitted/zip`,
      downloadPortalFile: `api/files/submitted/{id}`,
      siteSearch: `api/sites`,
      getPendingFiles: `api/file-aggregations/user-pending-files`,
      getPendingFilesCard: `api/file-aggregations/pending-files-summary`,
      getFullfilledFiles: `api/file-aggregations/fulfilled-files`,
      portalFileActions: `api/files`,
      portalFileActionsReject: `api/files/reject`,
      portalFileActionsAccept: `api/files/accept`,
      portalFileRequests: `api/file-requests`,
      portalFileRequestById: `api/ext/requests/{id}`,
      validatePortalFileRequest: `api/ext/requests/{requestId}/token`,
      submitPortalFiles: `api/file-requests/{id}/file`,
    },
    [OperationalServiceTypes.FileStorageService]: {
      uploadFiles: `api/files`,
      copyFiles: `api/files/copy`
    },
    organizationAPI: {
      verticals: `api/verticals`,
      integrations: `api/organizations/integrations`,
      supportLevels: `api/licenses/supportlevels`,
      compliances: `api/licenses/compliances`,
      licenses: `api/licenses`,
      licenseStatuses: `api/licenses/statuses`,
      menus: `api/menus`,
      organizations: `api/organizations`,
      storages: `api/licenses/storages`,
      userCounts: `api/licenses/usercounts`,
      functionalFlow: `api/functionalflows`,
      functionalFlowByLicence: `api/organizations/{id}/flows`,
      updateFunctionalFlows: `api/organizations/{id}/flows`,
      getEnabledFlows: `api/organizations/{id}/modules/flows`,
      countries: `api/organizations/countries`,
      statusesByLicense: `api/licenses/{id}/statuses`,
    },
    [OperationalServiceTypes.FileManagementService]: {
      getPublishedFilesColumns: `api/files/published-files/columns`,
      getPublishedFiles: `api/files/published-files`,
      getPublishedFilesCount: `api/files/published-files?count=true`,
      downloadFile: `api/files/{id}`,
      downloadZipFile: `api/files/zip`,
      downloadPublishedFile: `api/files/published-files/{id}`,
      downloadPublishedZipFile: `api/files/published-files/zip`,
      deleteFilesByIds: `api/files`,
      fileDetailsByFile: `api/files/{id}/details`,
      files: `api/files`,
      fileAreasByChannel: `api/fileAreas`,
      fileAreasByChannelList: `api/FileAreas/fileArea-list`,
      fileStatuses: `api/tenants/fileStatuses`,
      fileStatus: `api/files/status`,
      years: `api/tenants/years`,
      projectsBySite: `api/fileareas/{id}/projects`,
      tagsBySite: `api/fileareas/{id}/tags`,
      usersBySite: `api/fileareas/{id}/users`,
      saveFileDetails: `api/files/details`,
      saveAcceptFileDetails: `api/files/accept/details`,
      folderStructureBySite: `api/fileAreas/{id}/folders`,
      tags: `api/FileAreas/{id}/tags`,
      fileAreaSettingsBySite: `api/fileAreas/{id}/settings`,
      checkout: `api/files/{id}/checkout`,
      undoCheckout: `api/files/{id}/undo`,
      checkedOutFiles: `api/files/checkouts`,
      checkedOutFilesHistory: `api/files/{id}/checkouts`,
      assignmentFilesHistory: `api/files/{id}/assignments`,
      updateAssigneeNStatus: `api/files/assignee`,
      checkiIn: `api/files/checkin`,
      createFilterTemplate: `api/fileAreas/{id}/templates`,
      modifyFilterTemplate: `api/fileAreas/{siteId}/templates/{templateId}`,
      deleteFilterTemplate: `api/fileAreas/{siteId}/templates/{templateId}`,
      getTemplates: `api/fileAreas/{id}/templates`,
      portalFileRequestWithKey: `api/file-requests/{id}`,
      recategorizeFiles: `api/files/fileArea/{id}`,
      updateFileRequest: `api/file-requests/{id}`,
      publishFiles: `api/files/published-files`,
      getFileHistory: `api/files/file-eventhistory`,
      lastAccessedSites: `api/files/last-accessedsites`,
      lastAccessedFiles: `api/files/last-accessedfiles`,
      retentions: `api/retentions`,
      retentionsSoftDelete: `api/retentions/SoftDelete`,
      retentionsCSV: `api/Retentions/reports/csv`,
      recategorize: `api/files/recategorize`,
      moveFiles: `api/files/move`,
      reNameFiles: `api/files/rename`,
      pin: `api/files/pin`,
    },
    [OperationalServiceTypes.UserService]: {
      userPermissions: `api/permissions/logged-user`,
      userNavigationMenu: `api/users/internal-menu`,
      crossOrganizationUserExsistance: `api/users`,
      createUser: `api/users`,
      userById: `api/users/{id}`,
      getUserRoles: `api/users/roles`,
      getUserStatuses: `api/users/statuses`,
      getUserCount: `api/users/count`,
      requestResetPassword: `api/users/password`,
      getUserGroups: `api/groups`,
      usersByGroupId: `api/groups/{id}/users/user-list`,
      getModulePermission: `api/permissions`,
      createUserGroup: `api/groups`,
      userGroupByuser: `api/users/{id}/groups/group-list`,
      getUserGroup: `api/groups/group-list`,
      getUserList: `api/users/user-list`,
      userGroupById: `api/groups/{id}`,
      modulePermissionGroupById: `api/groups/{id}/permissions`,
    },
    [OperationalServiceTypes.MasterDataService]: {
      channels: `api/channels/channel-list`,
      createChannel: `api/channels`,
      channelById: `api/channels/{id}`,
      checkChannelExsistance: `api/channels`,
      getFolders: `api`,
      getSitesByChannel: `api/channels/{id}/sites`,
      createContact: `api/contacts`,
      createSite: `api/channels/{channelId}/sites`,
      checkSiteByChannel: `api/channels/{channelId}/sites`,
      updateSite: `api/channels/{channelId}/sites/{siteId}`,
      clientList: `api/contacts/contact-list`,
      sites: `api/sites`,
      siteById: `api/sites/{id}`,
      contactsBysiteId: `api/sites/{siteId}/contacts`,
      crmSiteIdAvailable: 'api/Sites/crmsite',
      siteNameAvaialble: 'api/channels/site',
      templates: 'api/Templates',
      updateTemplates: 'api/Templates/{templateId}',
      foldersBySiteId: `api/sites/{siteId}/folders`,
      autoFilingRules: `api/AutoFilingRules`,
    },
    [OperationalServiceTypes.DashboardService]: {
      getWidgetPermissions: `api/dashboards/{id}/widgets`,
      firmLogoAndName: `api/Dashboards/firmDetails`,
    },
    [OperationalServiceTypes.BffService]: {
      filter: `api/filters`,
      filterById: `api/filters/{id}`,
      autoFilingLogs: `api/AutoFilingLogs`
    },
    [OperationalServiceTypes.NotificationService]: {
      pushNotification: `api/notificationHub`,
    },
  },
  inputDebounceInterval: 500,
  dateFormat: 'YYYY-MM-DD',
  idleTime: 6000000,
  clientId: '0oa4i11z6n6ZsiPed0x7',
};

const changeApi: AppConfig = ((): AppConfig => {
  Object.keys(config.api).forEach((e) => {
    Object.keys((config.api as any)[e]).forEach((v) => {
      (config.api as any)[e][v] = e + '/' + (config.api as any)[e][v];
    });
  });
  return config;
})();

export default changeApi;
