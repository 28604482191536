import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { DataNode } from 'rc-tree/lib/interface';
import { Rule } from 'antd/lib/form';
import axios from 'axios';

import FolderTree from '@app/components/FolderTree';
import styles from './index.module.less';
import { formActionType } from '@app/utils';
import { required } from '../../components/forms/validators';
import { ValueType } from '@app/types/ValueType';
import { checkChannelExsistance } from '@app/api/channelService';
import { prevenPrecedingSpaces, prevenPrecedingSpacesOnPaste } from '@app/utils/forms';

export interface Channnel {
  action: formActionType;
  formRef?: FormInstance;
  onFinish?: (values: any) => void;
  onChange?: () => void;
  onFolderTreeUnmount?: (treeData: DataNode[]) => void;
  channelDetails?: any;
  channelDetailWithFolderStructure: any;
  onDupicateFolderAdded?: (duplicateName: boolean) => void;
  onEmptyFolderAdded?: (duplicateFolder: boolean) => void;
}

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const getPopupContainer = (trigger: any) => {
  return trigger.parentNode as HTMLElement;
};

const renderDropdownOptions = (option: ValueType) => {
  return (
    <Option key={option.value} value={option.value}>
      {option.name}
    </Option>
  );
};

export default ({
  action,
  formRef,
  onChange,
  onFinish,
  onFolderTreeUnmount,
  channelDetails,
  onDupicateFolderAdded,
  onEmptyFolderAdded,
  channelDetailWithFolderStructure,
}: Channnel) => {
  const [folderData, setFolderData] = useState<Array<DataNode>>([]);
  let cancelToken: any;

  const exsisChannel = (channelName: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      //retrun true if it's edit with same name
      if (action === 'edit' && channelDetailWithFolderStructure && channelName === channelDetailWithFolderStructure.name) {
        resolve(true);
      }
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      cancelToken = axios.CancelToken.source();
      return checkChannelExsistance(channelName, cancelToken)
        .then(() => {
          reject('Office Name already exists');
        })
        .catch(() => {
          resolve(true);
        });
    });
  };

  const channelExsistance = (): Rule => ({
    validator(rule, value) {
      return value ? exsisChannel(value) : Promise.resolve();
    },
  });

  const commonChannelDetails = () => (
    <>
      <Col span={8}>
        <Form.Item label={'Office Name'} name="name" rules={[required, channelExsistance()]}>
          <Input onInput={(event) => prevenPrecedingSpacesOnPaste(event)} onKeyDown={(event) => prevenPrecedingSpaces(event)} maxLength={50} autoComplete="off" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item rules={[required]} initialValue={1} label="Status" name="status">
          <Select notFoundContent={`No User Status Avaialble`} disabled={true} style={{ width: '100%' }} getPopupContainer={getPopupContainer}>
            {channelDetails && channelDetails.status ? (channelDetails.status as ValueType[]).map((option) => renderDropdownOptions(option)) : null}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8} className={'yjChannelDescription'}>
        <Form.Item label={'description'} name="description" rules={[required]}>
          <TextArea
            onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
            onKeyDown={(event) => prevenPrecedingSpaces(event)}
            maxLength={100}
            autoComplete="off"
            autoSize={{ minRows: 1, maxRows: 2 }}
          />
        </Form.Item>
      </Col>
    </>
  );
  const folderStructureData = () => (
    <Col span={24}>
      <h2>Folder Structure</h2>
      {folderData.length <= 0 && <p style={{ color: 'red' }}>At least one folder is required</p>}
      <div className={action === 'edit' ? `${styles.yjFolderStructureTreeWrapper} ${styles.yjFolderStructureTreeUpdate}` : `${styles.yjFolderStructureTreeWrapper}`}>
        <FolderTree
          onFolderTreeUnmount={(treeData) => {
            setFolderData(treeData);
            onFolderTreeUnmount && onFolderTreeUnmount(treeData);
          }}
          data={channelDetailWithFolderStructure ? channelDetailWithFolderStructure.folders : undefined}
          onChange={onChange}
        />
      </div>
    </Col>
  );

  const generateEditMode = () => (
    <>
      <Row gutter={16}>{commonChannelDetails()}</Row>
      <Row gutter={24}>{folderStructureData()}</Row>
    </>
  );

  const generateUpdatedMode = () => (
    <>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label={'Office Id'} name="officeKey" rules={[required]}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Office Name'} name="name" rules={[required, channelExsistance()]}>
            <Input onInput={(event) => prevenPrecedingSpacesOnPaste(event)} onKeyDown={(event) => prevenPrecedingSpaces(event)} maxLength={50} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item rules={[required]} initialValue={1} label="Status" name="status">
            <Select notFoundContent={`No User Status Avaialble`} disabled={true} style={{ width: '100%' }} getPopupContainer={getPopupContainer}>
              {channelDetails && channelDetails.status ? (channelDetails.status as ValueType[]).map((option) => renderDropdownOptions(option)) : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'description'} name="description">
            <TextArea
              onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
              onKeyDown={(event) => prevenPrecedingSpaces(event)}
              maxLength={100}
              autoComplete="off"
              autoSize={{ minRows: 1, maxRows: 2 }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Created By'} name="createdBy">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Created Date'} name="created">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Modified Date'} name="modified">
            <Input disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      {/*<Row gutter={24}>{folderStructureData()}</Row>*/}
    </>
  );

  return (
    <div className={styles.yjAddNewChannelWrapper}>
      <h2>Basic Information</h2>
      <Form form={formRef} key="addChannelForm" onFinish={onFinish} {...layout} layout="horizontal" onChange={onChange}>
        {action === 'save' && generateEditMode()}
        {action === 'edit' && generateUpdatedMode()}
      </Form>
    </div>
  );
};
